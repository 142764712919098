import { ConferenceVO } from "../requestModel";

/** 查询活动列表 */
export interface IEventsListReq {
  nextToken?: string | null;
  limit: number;
  type: EventsTypeModel;
}

/** 查询活动详情 */
export type TEventsDetailReq = { id: string };
/**
 * 取消报名
 */
export type TCancelEventsReq = { conferenceId: string };
/**
 * 报名活动
 */
export type TRegisterEventsReq = { conferenceId: string; attendNum: number; otherName?: string };
/**
 * 活动类型
 */
export type EventsTypeModel = "ONLINE" | "OFFLINE" | "";

export interface IRegisterInfoModel {
  time: string;
  host: string;
  price: string;
  location: string;
  attachment: string;
}

export interface EventsListItemProps {
  info: ConferenceVO;
}

export type IEventPage = "All" | "Online" | "InPerson";
export type IEventStatus = "coming" | "inProgress" | "end" | "";
export enum EEventType {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE"
}
