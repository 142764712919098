/**
 * saas路由常量配置
 * 此配置只会增加，或者修改常量值，常量名尽量不要修改，
 * 不能修改的常量值需要明确标注
 */

/**
 * 各系统路由常量配置，在app中组合路由，生成对应routeConfig
 * saas、operation单独配置，portal、service、partner沿用之前路由
 * 各系统调用getPageHeader方法，checkAuth等方法时，从对应系统_app以传参形式传入
 */

// saas 路由
export const SAAS = {
  LOGIN: "/login",
  VENTURES: "/ventures",
  VENTURES_DETAIL: "/ventures/detail",
  STRATEGIES: "/strategies",
  STRATEGIES_DETAIL: "/strategies/detail",
  INVESTOR: "/investor",
  INVESTOR_DETAIL: "/investor/detail",
  INVESTOR_AUTHOR_LIST: "/investor/author",
  INVESTOR_AUTHOR_DETAIL: "/investor/author/detail",
  INVESTMENT: "/investment",
  WALLET: "/wallet",
  MARKET: "/market",
  CONTACT: "/contact"
};
