import React from "react";
import { PieChart } from "../../charts";
import styles from "./TotalAssetsChart.module.less";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import {
  degree2Angle,
  decimalPointNoMoreX,
  numberToThousands,
  getTotalAssetsFontSize,
  reportEvent,
  BENCHMARK_CURRENCY_SYMBOL,
  GA_EVENT_NAME
} from "@aspen/libs";
import Image from "next/image";

interface IProps {
  key?: string;
  width?: number;
  showAssets: boolean;
  showEyeIcon?: boolean; // 是否显示 隐藏资金按钮
  totalNumber: number | string;
  totalText: string;
  handleToggleAssets?: (show: boolean) => void;
  color?: Array<string>;
  pieList: Array<{
    title: string;
    value: number;
  }>;
}

interface IState {
  ratio: number;
  circleRadius: number;
  outerRadius: number;
  innerRadius: number;
  chartRadius: number;
  degree: number; //外环 & 空心环底部的角度/2
  chartDegree: number; //图表底部的角度/2
}

const totalassets = require("@aspen/assets/images/ic_totalassets@2x.png").default;

export class TotalAssetsChart extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { width = 0 } = this.props;
    const ratio = width / 320;

    this.state = {
      circleRadius: (ratio * 320) / 2,
      outerRadius: (ratio * 294) / 2,
      innerRadius: (ratio * 236) / 2,
      chartRadius: (ratio * 280) / 2,
      degree: 50,
      chartDegree: 55,
      ratio: ratio
    };
  }

  canvas: HTMLCanvasElement;

  static defaultProps: IProps = {
    width: 320,
    showAssets: true,
    showEyeIcon: true,
    totalText: "",
    totalNumber: 0,
    handleToggleAssets: () => {},
    pieList: [
      {
        title: "",
        value: 0
      },
      {
        title: "",
        value: 0
      },
      {
        title: "",
        value: 0
      }
    ]
  };

  render(): React.ReactNode {
    const { circleRadius, chartDegree, degree, chartRadius } = this.state;
    const { showAssets, totalNumber, color, showEyeIcon, pieList } = this.props;
    const totalAssetStr =
      BENCHMARK_CURRENCY_SYMBOL + numberToThousands(decimalPointNoMoreX(totalNumber, 2));

    return (
      <div
        style={{
          width: `${circleRadius * 2}px`,
          height: `${circleRadius + circleRadius * Math.sin(degree2Angle(90 - degree))}px`,
          position: "relative"
        }}>
        {/* @ts-ignore */}
        <canvas width={circleRadius * 2} height={circleRadius * 2} ref={(r) => (this.canvas = r)} />
        <div
          className={styles.full}
          style={{
            top: circleRadius - chartRadius - 1,
            left: circleRadius - chartRadius - 1
          }}>
          <PieChart
            width={chartRadius * 2}
            height={chartRadius + chartRadius * Math.sin(degree2Angle(90 - chartDegree))}
            degree={chartDegree}
            colorField={"title"}
            angleField={"value"}
            color={color}
            list={pieList}
          />
        </div>
        <div className={styles.full + " " + styles.center} style={{}}>
          <div className={styles.total} style={{ top: 0 }}>
            <span className={styles.totalIcon}>
              <Image unoptimized src={totalassets} alt="" width="32" height="32" />
            </span>

            <p style={{ marginBottom: "3px", cursor: "pointer" }}>
              <span style={{ paddingRight: "5px", fontFamily: "Common-Medium" }}>
                {this.props.totalText}
              </span>
              {showEyeIcon &&
                (showAssets ? (
                  <span
                    onClick={() => {
                      // @ts-ignore
                      this.props.handleToggleAssets(false);
                      reportEvent({
                        moduleName: GA_EVENT_NAME.home.hideTotalAssets,
                        detailParams: { businessType: "Asset" }
                      });
                    }}>
                    <EyeOutlined className="pointerNone" />
                  </span>
                ) : (
                  <span
                    onClick={() => {
                      // @ts-ignore
                      this.props.handleToggleAssets(true);
                      reportEvent({
                        moduleName: GA_EVENT_NAME.home.showTotalAssets,
                        detailParams: { businessType: "Asset" }
                      });
                    }}>
                    <EyeInvisibleOutlined className="pointerNone" />
                  </span>
                ))}
            </p>
            <p
              className={styles.totalNumber}
              style={{
                fontSize: getTotalAssetsFontSize(totalAssetStr.length),
                lineHeight: "40px"
              }}>
              {showAssets ? totalAssetStr : "****"}
            </p>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    let { degree, circleRadius, outerRadius, innerRadius } = this.state;
    // const canvas = document.getElementById("circle-assets");
    circleRadius = circleRadius - 1;
    const canvas = this.canvas;
    // @ts-ignore
    if (!canvas.getContext) {
      return;
    }
    // @ts-ignore
    const ctx: any = canvas.getContext("2d");

    // check @张琦 @赵靖
    let dpr = 1; //window.devicePixelRatio || 2;
    // 获取css的宽高
    let { width: cssWidth, height: cssHeight } = canvas.getBoundingClientRect();
    // 根据dpr，扩大canvas画布的像素，使1个canvas像素和1个物理像素相等
    canvas.style.width = canvas.width + "px";
    canvas.style.height = canvas.height + "px";

    canvas.width = dpr * cssWidth;
    canvas.height = dpr * cssHeight;
    // 由于画布扩大，canvas的坐标系也跟着扩大，如果按照原先的坐标系绘图内容会缩小
    // 所以需要将绘制比例放大
    ctx.scale(dpr, dpr);

    ctx.beginPath();
    ctx.arc(
      circleRadius,
      circleRadius,
      outerRadius,
      degree2Angle(90 + degree),
      degree2Angle(90 - degree),
      false
    );

    ctx.arc(
      circleRadius,
      circleRadius,
      innerRadius,
      degree2Angle(90 - degree),
      degree2Angle(90 + degree),
      true
    );
    ctx.fillStyle = "#2B2D3A";
    ctx.fill();

    ctx.beginPath();
    ctx.arc(
      circleRadius,
      circleRadius,
      circleRadius,
      degree2Angle(90 + degree),
      degree2Angle(90 - degree),
      false
    );
    ctx.lineWidth = 1.2;
    ctx.strokeStyle = "#2B2D3A";
    ctx.stroke();
  }

  componentWillUnmount() {}
}
