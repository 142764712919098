import React from "react";
import styles from "./EmptyCharts.module.less";
import Image from "next/image";
import empty from "@aspen/assets/images/assetsEmpty.png";
import { i18nUtil } from "@aspen/libs";

export const EmptyCharts = () => {
  const intl = i18nUtil.t();

  const chartNoData: string = intl["noData"];

  return (
    <div className={styles.emptyCharts}>
      <div className={styles.emptyImg}>
        <Image unoptimized src={empty} alt="" width="165" height="121" />
      </div>
      <span>{chartNoData}</span>
    </div>
  );
};
