import React, { Component } from "react";
import Image from "next/image";
import styles from "./Login.module.less";
import logo from "@aspen/assets/images/aspen_logo_white.png";
import { handleBlur, handleFocus, i18nUtil, REG_EXP_PASSWORD } from "@aspen/libs";
import LanguageSwitch from "./LanguageSwitch";
import clsx from "clsx";

interface IProps {}

interface IState {}

export const LoginLayoutCMS = (WrappedComponent) => {
  class LoginCMS extends Component<IProps, IState> {
    constructor(props: IProps) {
      super(props);

      this.state = {};
    }

    /**
     * @description: 校验确认密码格式以及是否和密码一致，重置密码和设置密码使用
     * @params {
     *  confirmPassword：确认密码，
     *  password：密码，
     *  message：格式或者长度错误提示，
     *  notMatch：两次密码不一致时的提示
     * }
     * @return {Promise}
     */
    passwordConfirmValidator: (
      confirmPassword: string,
      password: string,
      message: string,
      notMatch: string
    ) => Promise<any> = (
      // promise, any不修改
      confirmPassword,
      password,
      message,
      notMatch
    ) => {
      if (!confirmPassword) {
        return Promise.reject(new Error(message));
      }
      if (password !== confirmPassword) {
        return Promise.reject(new Error(notMatch));
      }
      if (
        !confirmPassword ||
        password.length !== confirmPassword.length ||
        !REG_EXP_PASSWORD.test(confirmPassword)
      ) {
        return Promise.reject(new Error(message));
      }
      return Promise.resolve();
    };

    componentDidMount(): void {}

    render() {
      const intl = i18nUtil.t();
      return (
        <section className={clsx(styles.login, "login")}>
          <LanguageSwitch />
          <div className={styles.main}>
            <div className={styles.mainContainer}>
              <section className={styles.leftBriefBlack}>
                <span className={styles.logo}>
                  <Image unoptimized src={logo} alt="" />
                </span>
                <p className={styles.title}>{intl?.["login.title.CMS"]}</p>
              </section>
              <WrappedComponent {...this.props} handleFocus={handleFocus} handleBlur={handleBlur} />
            </div>
          </div>
        </section>
      );
    }
  }

  return LoginCMS;
};
