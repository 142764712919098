import React, { memo } from "react";
import { i18nUtil } from "@aspen/libs";
import { withRouter } from "next/router";
import { IInjectProps } from "@aspen/model";
import { ModalBase } from "@aspen/ui";

interface IProps extends IInjectProps {
  visible: boolean;
  handleCancel: () => void;
  handleOk: () => void;
}

interface IState {}

class ModalRegisterCancelView extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render(): React.ReactNode {
    const intl = i18nUtil.t();
    return (
      <ModalBase
        title={intl["modal.confirm.title"]}
        centered
        closable={true}
        maskClosable={false}
        open={this.props.visible}
        okText={intl["button.confirm"]}
        cancelText={intl["button.cancel"]}
        onOk={this.props.handleOk}
        onCancel={this.props.handleCancel}>
        <p>{intl["event.cancel.message"]}</p>
      </ModalBase>
    );
  }
}
const ModalRegisterCancel = memo(ModalRegisterCancelView);
export { ModalRegisterCancel };
