import React, { useEffect, useState } from "react";
import styles from "@aspen/theme/Structured.module.less";
import { Radio, RadioChangeEvent } from "antd";
import { message } from "@aspen/ui/customMessage";
import { StructuredTableList } from "./StructuredTableList";
import { getStructuredUnderlying } from "@aspen/services";
import {
  convertUSD2USDC,
  GA_EVENT_NAME,
  i18nUtil,
  reportEvent,
  STRUCTURED_TYPES
} from "@aspen/libs";
import { IStructuredProductModel } from "@aspen/model";

type IUnderlyingTypeMapModel = { [key: string]: string[] };
export const StructuredTableWrapper: React.FC = () => {
  const intl = i18nUtil.t();
  // 交易对
  const [underlying, setUnderlying] = React.useState<string[]>([]);
  // 当前选中的交易对
  const [currentUnderlying, setCurrentUnderlying] = useState<string>();
  // Direction tab
  const [direction, setDirection] = useState<string[]>([]);
  // 当前Direction
  const [currentDirection, setCurrentDirection] = useState<string>();
  const [underlyingTypeMap, setUnderlyingTypeMap] = React.useState<IUnderlyingTypeMapModel>();

  useEffect(() => {
    _requestUnderlyingData();
  }, []);
  useEffect(() => {
    handleDirection();
  }, [currentUnderlying]);
  const handleUnderlyingChange = (e: RadioChangeEvent) => {
    setCurrentUnderlying(e.target.value);
    reportEvent({
      moduleName: GA_EVENT_NAME.structured.switchProduct,
      detailParams: {
        underlying: e.target.value,
        direction: currentDirection || "",
        productType: STRUCTURED_TYPES.dual
      }
    });
  };
  const handleDirectionChange = (index: number) => {
    setCurrentDirection(direction[index]);
    reportEvent({
      moduleName: GA_EVENT_NAME.structured.switchDirection,
      detailParams: {
        direction: direction[index],
        underlying: currentUnderlying || "",
        productType: STRUCTURED_TYPES.dual
      }
    });
  };
  const handleDirection = () => {
    for (let key in underlyingTypeMap) {
      if (currentUnderlying === key) {
        let direction = [];
        for (let i = 0; i < underlyingTypeMap[key].length; i++) {
          const directionKey = underlyingTypeMap[key][i]?.split(":")[1] || "";
          // @ts-ignore
          direction.push(directionKey);
        }
        setDirection(direction);
        if (!currentDirection) {
          setCurrentDirection(direction && direction[0]);
        }
      }
    }
  };
  const handleTypeList = (underlyings: string[], data: IStructuredProductModel[]) => {
    let res: IUnderlyingTypeMapModel = {};
    for (let i = 0; i < underlyings.length; i++) {
      let _underlying = underlyings[i]?.split("-")[0] || "";
      let _quote = underlyings[i]?.split("-")[1] || "";
      res[underlyings[i]] = [];
      for (let j = 0; j < data.length; j++) {
        if (
          data[j].underlying === _underlying &&
          convertUSD2USDC(data[j].quote) === convertUSD2USDC(_quote)
        ) {
          res[underlyings[i]].push(data[j].productId);
        }
      }
    }
    setUnderlyingTypeMap(res);
  };
  const _requestUnderlyingData = () => {
    getStructuredUnderlying()
      .then(async (res) => {
        if (res?.code == "0") {
          const { data } = res;
          let len = data?.length ?? 0;
          let i = 0;
          let underlyings: string[] = [];
          while (i <= len - 1) {
            let name = `${data[i]?.underlying}-${convertUSD2USDC(data[i]?.quote)}`;
            if (underlyings.indexOf(name) === -1) {
              underlyings.push(name);
            }
            i++;
          }
          handleTypeList(underlyings, data);
          setUnderlying(underlyings);
          setCurrentUnderlying(underlyings[0]);
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .catch((err) => {
        message.error(intl?.[err?.msg] ?? err?.msg);
      });
  };
  return (
    <div className={styles.structuredTable}>
      <div className={styles.structuredTableTitle}>
        {/* 交易对tab（一级tab */}
        <Radio.Group
          onChange={handleUnderlyingChange}
          value={currentUnderlying}
          style={{ marginBottom: 24 }}>
          {underlying.map((item, index) => {
            return (
              <Radio.Button key={`underlying-${index}`} value={item}>
                {item}
              </Radio.Button>
            );
          })}
        </Radio.Group>
        {/* Direction tab（二级tab */}
        <div className={styles.directionTabTitle}>
          {direction.map((item, index) => {
            return (
              <span
                onClick={() => {
                  handleDirectionChange(index);
                }}
                key={`direction-${index}`}
                className={currentDirection == item ? styles.focus : ""}>
                {intl[`structured.${item?.toLocaleLowerCase()}`]}
              </span>
            );
          })}
        </div>
        {/* TableList */}
        <StructuredTableList
          // @ts-ignore
          type={currentDirection}
          underlying={currentUnderlying?.split("-")[0] || ""}
          quote={currentUnderlying?.split("-")[1] || ""}
          // @ts-ignore
          name={currentUnderlying}
        />
      </div>
    </div>
  );
};
