import React, { forwardRef, useImperativeHandle, useRef } from "react";
import ReactPlayer from "react-player";
import styles from "./CommonVideo.module.less";
import SkeletonNode from "antd/lib/skeleton/Node";

interface IProps {
  url: string | undefined;
  controls?: boolean;
  onReady?: () => void;
  onStart?: () => void;
  onPlay?: () => void;
  onPause?: () => void;
  onEnded?: () => void;
  onError?: (e) => void;
}

export const CommonVideo = forwardRef((props: IProps, ref) => {
  const { url, controls = true, onReady, onStart, onPlay, onPause, onEnded, onError } = props;
  const playerRef = useRef<ReactPlayer>(null);
  useImperativeHandle(ref, () => ({
    // 实例回调
  }));
  const _onReady = () => {};
  const _onStart = () => {};
  const _onPlay = () => {};
  const _onPause = () => {};
  const _onEnded = () => {};
  const _onError = () => {};
  return (
    <div className={styles.playerWrapper}>
      {url ? (
        <ReactPlayer
          ref={playerRef}
          className="react-player"
          width="100%"
          url={url}
          controls={controls}
          onReady={onReady || _onReady}
          onStart={onStart || _onStart}
          onPlay={onPlay || _onPlay}
          onPause={onPause || _onPause}
          onEnded={onEnded || _onEnded}
          onError={onError || _onError}
        />
      ) : (
        <SkeletonNode className={styles.skeletonNode} />
      )}
    </div>
  );
});
