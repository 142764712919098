import { INVESTOR_PORTAL_TYPE, IPagerInfo } from "@aspen/model";

// saas BusinessType
export enum SaasBusinessType {
  emailCode = 14
}

export const SAAS_VENTURE_TAB = {
  primary: "primary",
  secondary: "secondary",
  deal: "deal",
  fund: "fund",
  favorites: "favorites"
};

export const SAAS_INVESTMENT_TAB = {
  venture: "venture",
  strategy: "strategy"
};

export const SAAS_INVESTOR_TAB = {
  updates: "updates",
  news: "news",
  research: "research",
  expert: "expert",
  otherExperts: "otherExperts",
  report: "report"
};

export const SAAS_INVESTOR_PARAMS_INFORMATION_TYPE: { [key: string]: INVESTOR_PORTAL_TYPE } = {
  updates: "UPDATES",
  news: "NEWS",
  research: "RESEARCH",
  expert: "EXPERTS",
  otherExperts: "EXPERTS",
  report: "REPORTS"
};

export const SAAS_INVESTOR_PARAMS_TYPE: {
  [key: string]:
    | "NEWS"
    | "STORY"
    | "NEWS_FLASH"
    | "NOTICE"
    | "BRIEF"
    | "REPORT"
    | "MONTHLY_REVIEW"
    | "WEEKLY_REPORT"
    | "EXPERT_POST";
} = {
  NEWS: "STORY",
  RESEARCH: "REPORT",
  EXPERTS: "EXPERT_POST"
};

export const SAAS_INVESTOR_PARAMS_ACTION: { [key: string]: "ALL" | "RECOMMEND" } = {
  ALL: "ALL",
  RECOMMEND: "RECOMMEND"
};

export const SAAS_INVESTOR_PARAMS_EXPERT_TYPE: { [key: string]: "ALL" | "CHOSEN" | "FOLLOWED" } = {
  ALL: "ALL",
  CHOSEN: "CHOSEN",
  FOLLOWED: "FOLLOWED"
};

export enum SAAS_VENTURE_SHARES {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  DEAL = "DEAL",
  FUND = "FUND"
}

export const SAAS_VENTURE_DETAIL_TAB = {
  detail: "detail",
  update: "update"
};

export const SAAS_INIT_PAGE_INFO: IPagerInfo = { page: 1, nextToken: "" };
export const SAAS_DEFAULT_LIMIT = 9;
export const SAAS_DEFAULT_TABLE_LIMIT = 10;
export const SAAS_DEFAULT_INVESTOR_LIMIT = 15;

export const SAAS_VENTURE_ASSET_TYPE = {
  Token: 0,
  Equity: 1,
  Fund: 2,
  NFT: 3
};

export const venture_investment_column = ["date", "venture", "stage", "type", "amount"];
export const venture_investment_dataIndex = {
  date: "operatorTime",
  venture: "ventureName",
  stage: "shares",
  type: "type",
  amount: "amount"
};
export const strategy_investment_column = ["date", "strategy", "type", "amount"];
export const strategy_investment_dataIndex = {
  date: "operatorTime",
  strategy: "strategyName",
  type: "type",
  amount: "amount"
};

export const venture_wallet_column = [
  "venture",
  "stage",
  "changeAmount",
  "changePercent",
  "fairValue",
  "control"
];
export const venture_wallet_dataIndex = {
  venture: "ventureName",
  stage: "shares",
  changeAmount: "changeAmount",
  changePercent: "changePercent",
  fairValue: "fairValue",
  control: "control"
};
export const strategy_wallet_column = [
  "strategy",
  "type",
  "changeAmount",
  "changePercent",
  "fairValue",
  "control"
];
export const strategy_wallet_dataIndex = {
  strategy: "strategyName",
  type: "strategyType",
  changeAmount: "changeAmount",
  changePercent: "changePercent",
  fairValue: "fairValue",
  control: "control"
};

// saas公用接口和参数一致的请求，可以在这里配置
export const SAAS_COMMON_CACHE_PRIORITY_KEYS = {
  saasWalletInfo: "saasWalletInfo",
  saasInterestVentures: "saasInterestVentures"
} as const;

export const SASS_PERMISSION_KEY = "sass_permisson_config";
// market曲线图
import fail_0 from "@aspen/assets/images/saas/market/fail_0.png";
import fail_1 from "@aspen/assets/images/saas/market/fail_1.png";
import fail_2 from "@aspen/assets/images/saas/market/fail_2.png";
import fail_3 from "@aspen/assets/images/saas/market/fail_3.png";
import fail_4 from "@aspen/assets/images/saas/market/fail_4.png";
import rise_0 from "@aspen/assets/images/saas/market/rise_0.png";
import rise_1 from "@aspen/assets/images/saas/market/rise_1.png";
import rise_2 from "@aspen/assets/images/saas/market/rise_2.png";
import rise_3 from "@aspen/assets/images/saas/market/rise_3.png";
import rise_4 from "@aspen/assets/images/saas/market/rise_4.png";

export const Saas_Market_Rise = [rise_0, rise_1, rise_2, rise_3, rise_4];

export const Saas_Market_Fail = [fail_0, fail_1, fail_2, fail_3, fail_4];

export const SaasIsRenderView = {
  showPoweredByAspen: "showPoweredByAspen"
};
