import dynamic from "next/dynamic";

export const InstitutionsBasic = dynamic(() => import("./kyc/InstitutionsBasic"), {
  ssr: false
});
export const IndividualsBasic = dynamic(() => import("./kyc/IndividualsBasic"), {
  ssr: false
});
export const Questionnaire = dynamic(() => import("./kyc/Questionnaire"), {
  ssr: false
});
export const Materials = dynamic(() => import("./kyc/Materials"), {
  ssr: false
});
export const IndividualsUploadFiles = dynamic(() => import("./kyc/IndividualsUploadFiles"), {
  ssr: false
});
export const InstitutionsUploadFiles = dynamic(() => import("./kyc/InstitutionsUploadFiles"), {
  ssr: false
});
export const KYCInfomations = dynamic(() => import("./KYCInfomations"), {
  ssr: false
});

export const BasicInfo = dynamic(() => import("./BasicInfo"), {
  ssr: false
});
export const SafetyInfo = dynamic(() => import("./SafetyInfo"), {
  ssr: false
});
export const SubAccount = dynamic(() => import("./SubAccount"), {
  ssr: false
});

export const PartnerAccount = dynamic(() => import("./PartnerAccount"), {
  ssr: false
});

export const ModalRiskReminder = dynamic(() => import("./modalRiskReminder/ModalRiskReminder"), {
  ssr: false
});

export const ModalBindGoogleAuth = dynamic(() => import("./safetyVerify/ModalBindGoogleAuth"), {
  ssr: false
});

export const ModalRemoveGoogleAuth = dynamic(() => import("./safetyVerify/ModalRemoveGoogleAuth"), {
  ssr: false
});
export const ModalChangePhone = dynamic(() => import("./safetyVerify/ModalChangePhone"), {
  ssr: false
});
export const ModalBindPhone = dynamic(() => import("./safetyVerify/ModalBindPhone"), {
  ssr: false
});
export const ModalUnbindPhone = dynamic(() => import("./safetyVerify/ModalUnbindPhone"), {
  ssr: false
});
export const ModalVerifySuccess = dynamic(() => import("./safetyVerify/ModalVerifySuccess"), {
  ssr: false
});
export const ServiceAccount = dynamic(() => import("./ServiceAccount"), {
  ssr: false
});
