import dynamic from "next/dynamic";
export * from "./emptyCharts/EmptyCharts";
export * from "./ColumnChart";
export * from "./Loading";
export * from "./PNLiquidChart";

export const PieChart = dynamic(() => import("./PieChart"), {
  ssr: false
});
export const LineChart = dynamic(() => import("./LineChart"), {
  ssr: false
});
