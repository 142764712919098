import React from "react";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { EN, ZH_CN, LANGUAGE, handleSwitchLanguage, i18nUtil, isClient } from "@aspen/libs/index";

const SwitchLanguage: React.FC = () => {
  const intl = i18nUtil.t();
  const locale = isClient ? window.localStorage.getItem(LANGUAGE) || EN : EN;
  const switchLanguage: MenuProps["onClick"] = (e) => {
    const lang = e.key;
    let href = location.href;

    // 英文切换中文
    if (locale === EN && lang === ZH_CN) {
      const path = location.href.slice(location.origin.length);
      href = `${location.origin}/${lang}/${path}`;
      handleSwitchLanguage(ZH_CN);
    }
    // 中文切换英文
    if (locale === ZH_CN && lang === EN) {
      href = location.href.replace(`/${locale}/`, "/");
      handleSwitchLanguage(EN);
    }

    location.href = href;
  };

  return (
    <Menu
      onClick={switchLanguage}
      items={[
        {
          label: <span style={{ marginLeft: 16 }}>{intl["zh"]}</span>,
          key: "zh"
        },
        {
          label: <span style={{ marginLeft: 16 }}>{intl["en"]}</span>,
          key: "en"
        }
      ]}
    />
  );
};
export default React.memo(SwitchLanguage);
