"use client";
import type { RefObject } from "react";
import React, { Component } from "react";
import type { IVerifyParam } from "@aspen/model";
import { IS_GOOGLE_Certified } from "@aspen/libs";
import ModalSafetyVerification from "../modalSafetyVerification/ModalSafetyVerification";
import { ModalGoogleAuth } from "../modalGoogleAuth/ModalGoogleAuth";

interface IProps {
  ref: RefObject<WithEmailOrGoogleVerify>;
  handleConfirm: (param: IVerifyParam) => void;
  businessType?: string | number;
  verifyCodeToSelf?: boolean; // 代客操作时，若要发送验证码给子账户/代理商而不是主账号时，此参数需传ture
  [key: string]: any; // 此处any不修改
}

interface Istate {
  showGoogleAuthModal: boolean;
  showSafetyVerificationModal: boolean;
  verifyCode: string | number;
  verifyType: number | string;
  businessType: number | string;
}

export class WithEmailOrGoogleVerify extends Component<IProps, Istate> {
  constructor(props) {
    super(props);
    this.state = {
      showSafetyVerificationModal: false,
      showGoogleAuthModal: false,
      verifyCode: "",
      verifyType: 0,
      businessType: props.businessType ? props.businessType : 4 // 默认提币出金是邮箱校验4， 谷歌身份验证4
    };
    this.handleOk = this.handleOk.bind(this);
    this.hideVerifyModal = this.hideVerifyModal.bind(this);
  }

  // 如果绑定了谷歌身份验证器并且不是帮客，验证谷歌身份验证器，否则验证邮箱
  handleCheckVerify = () => {
    const isGoogleCertified =
      localStorage.getItem(IS_GOOGLE_Certified) == "1" ||
      localStorage.getItem(IS_GOOGLE_Certified) == "true"
        ? true
        : false;

    // 验证类型verifyType 1:邮箱 2：google验证器
    if (isGoogleCertified && !this.props.verifyCodeToSelf) {
      this.setState({
        verifyType: 2,
        showGoogleAuthModal: true
      });
    } else {
      this.setState({
        verifyType: 1,
        showSafetyVerificationModal: true
      });
    }
  };

  hideVerifyModal = () => {
    this.setState({
      showSafetyVerificationModal: false,
      showGoogleAuthModal: false
    });
  };

  //提交信息
  handleOk = (code?: string) => {
    const { verifyType, businessType } = this.state;
    const param = {
      verifyCode: code,
      verifyType: verifyType.toString(),
      businessType
    };
    // @ts-ignore
    this.props.handleConfirm(param);
  };

  render() {
    const { showSafetyVerificationModal, showGoogleAuthModal, businessType } = this.state;
    return (
      <>
        <ModalSafetyVerification
          // @ts-ignore
          visible={showSafetyVerificationModal}
          handleCancel={this.hideVerifyModal}
          verifyCodeToSelf={this.props.verifyCodeToSelf}
          businessType={businessType}
          isCustomerValidate
          onOk={this.handleOk}
        />
        <ModalGoogleAuth
          visible={showGoogleAuthModal}
          handleCancel={this.hideVerifyModal}
          onOk={this.handleOk}
        />
      </>
    );
  }

  // NOTICE: 如果有新增的props或者state参数，需要响应式变动，需要在这里添加处理
  shouldComponentUpdate(nextProps, nextState): boolean {
    return (
      nextState.showSafetyVerificationModal != this.state.showSafetyVerificationModal ||
      nextState.showGoogleAuthModal != this.state.showGoogleAuthModal ||
      nextState.businessType != this.state.businessType
    );
  }
}
