"use client";
import React from "react";

import { GA_PARAMS, IBankAccount, IFlexibleProducts, IInjectProps, IKycLevel } from "@aspen/model";
import { Button } from "antd";
import styles from "@aspen/theme/Home.module.less";
import { i18nUtil, REFERENCE_CURRENCY, WALLET_PATHS } from "@aspen/libs";
import { withRouter } from "next/router";
// const YieldComponent = dynamic(() => import("../home/index"));
import { YieldComponent } from "../index";

// TODO
// const ModalBankAccountStatus = dynamic(
//   () => import("../../widgets/wallet/modalBankAccountStatus/ModalBankAccountStatus")
// );
interface IProps extends IInjectProps {
  yieldProducts: IFlexibleProducts[];
  gaExtra?: GA_PARAMS; // 埋点用
  showGuideModal: (level: IKycLevel, isClose?: boolean) => boolean;
}
interface IState {
  showModal: boolean;
  circleInfo: IBankAccount | null;
}

class AssetSituationalNoDepositView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showModal: false,
      circleInfo: null
    };
  }
  getCircleInfo = (params) => {
    this.setState(
      {
        circleInfo: params
      },
      () => {
        this.showCircleModal();
      }
    );
  };
  showCircleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  goToDepositFiat = () => {
    this.props.router.push({
      pathname: WALLET_PATHS.WALLET_DEPOSIT_OTC
    });
    /* const intl = i18nUtil.t();
    if (this.props.showGuideModal(2)) {
      getBankAccount().then((res) => {
        if (res?.code == "0") {
          if (res?.data?.status == "active") {
            this.props.router.push({
              pathname: WALLET_PATHS.WALLET_DEPOSIT_USDC,
              query: {
                accountNumber: res?.data?.accountNumber
              }
            });
          } else {
            this.getCircleInfo(res?.data);
          }
        } else if (res?.code == 6001) {
          //未绑定
          this.props.router.push(WALLET_PATHS.WALLET_ADDACCOUNT);
        } else {
          let msg = intl?.[res?.msg];
          if (!msg) {
            msg = intl?.[res?.code] ?? intl?.["global.exception"];
          }
          message.error(msg);
        }
      });
    }*/
  };

  render(): React.ReactNode {
    const intl = i18nUtil.t();
    const { yieldProducts, gaExtra } = this.props;

    return (
      <section className={styles.noDeposit}>
        <p className={styles.title}>
          {i18nUtil.formatMessage(
            { id: "overview.assetSituational.title" },
            { currency: REFERENCE_CURRENCY }
          )}
        </p>
        <p className={styles.tips}>{intl["home.no.depsoit"]}</p>
        <div className={styles.actions}>
          <Button
            type="primary"
            style={{ marginRight: "16px" }}
            onClick={() => {
              this.props.router.push(WALLET_PATHS.WALLET_DEPOSIT);
            }}>
            {intl["button.deposit.crypto.currency"]}
          </Button>
          <Button className={styles.goToDepositFiatBtn} onClick={this.goToDepositFiat}>
            {intl["button.deposit.fiat"]}
          </Button>
        </div>
        <p className={styles.subTips}>{intl["home.no.depsoit.sub"]}</p>
        <div className={styles.savings} style={{ marginBottom: "16px" }}>
          {yieldProducts?.map((item: IFlexibleProducts) => (
            <YieldComponent
              showGuideModal={this.props.showGuideModal}
              yieldProductsItem={item}
              key={item.id}
              type="noDeposit"
              gaExtra={gaExtra}
            />
          ))}
        </div>

        {/* TODO
        <ModalBankAccountStatus
          showModal={this.state.showModal}
          // @ts-ignore
          circleInfo={this.state.circleInfo}
          showCircleModal={this.showCircleModal}
        /> */}
      </section>
    );
  }

  shouldComponentUpdate(nextProps, nextState): boolean {
    return (
      JSON.stringify(nextProps.yieldProducts) != JSON.stringify(this.props.yieldProducts) ||
      nextState.showModal != this.state.showModal
    );
  }
}

export const AssetSituationalNoDeposit = withRouter(AssetSituationalNoDepositView);
