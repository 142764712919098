import dynamic from "next/dynamic";

export const InsightsBanner = dynamic(() => import("./insights/InsightsBanner"), {
  ssr: false
});
export const TabInsights = dynamic(() => import("./insights/TabInsights"), {
  ssr: false
});
export const InsightsCard = dynamic(() => import("./insights/InsightsCard"), {
  ssr: false
});
export const LoadMore = dynamic(() => import("./insights/LoadMore"), {
  ssr: false
});
export const Research = dynamic(() => import("./recommend/Research"), {
  ssr: false
});
export const Expert = dynamic(() => import("./recommend/Expert"), {
  ssr: false
});
export const LatestNews = dynamic(() => import("./recommend/LatestNews"), {
  ssr: false
});
export const Announcement = dynamic(() => import("./recommend/Announcement"), {
  ssr: false
});
export const Report = dynamic(() => import("./recommend/Report"), {
  ssr: false
});
export const NewsBanner = dynamic(() => import("./news/NewsBanner"), {
  ssr: false
});
export const AnnouncementList = dynamic(() => import("./AnnouncementList"), {
  ssr: false
});
export const DailyAndWeeklyReport = dynamic(() => import("./DailyAndWeeklyReport"), {
  ssr: false
});
export const DetailInfo = dynamic(() => import("./DetailInfo"), {
  ssr: false
});
export const DownloadAppCard = dynamic(() => import("./DownloadAppCard"), {
  ssr: false
});
export const EmptyShareDetail = dynamic(() => import("./EmptyShareDetail"), {
  ssr: false
});
export const ExpertAuthorCardItem = dynamic(() => import("./ExpertAuthorCardItem"), {
  ssr: false
});
export const ExpertCardItem = dynamic(() => import("./ExpertCardItem"), {
  ssr: false
});
export const ExpertItem = dynamic(() => import("./ExpertItem"), {
  ssr: false
});
export const ExpertTopArticle = dynamic(() => import("./ExpertTopArticle"), {
  ssr: false
});
export const FeaturedExperts = dynamic(() => import("./FeaturedExperts"), {
  ssr: false
});
export const JumpApp = dynamic(() => import("./JumpApp"), {
  ssr: false
});
export const MarketDailyCardItem = dynamic(() => import("./MarketDailyCardItem"), {
  ssr: false
});
export const MarketDailyItem = dynamic(() => import("./MarketDailyItem"), {
  ssr: false
});
export const NewsCard = dynamic(() => import("./NewsCard"), {
  ssr: false
});
export const NewsCardItem = dynamic(() => import("./NewsCardItem"), {
  ssr: false
});
export const NewsItem = dynamic(() => import("./NewsItem"), {
  ssr: false
});
export const NewsMenu = dynamic(() => import("./NewsMenu"), {
  ssr: false
});
export const ResearchItem = dynamic(() => import("./ResearchItem"), {
  ssr: false
});
