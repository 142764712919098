import dynamic from "next/dynamic";
export const OtcHistory = dynamic(() => import("./OtcHistory"), {
  ssr: false
});

export const YieldFixedTable = dynamic(() => import("./YieldFixedTable"), {
  ssr: false
});

export const NewYieldFixedTable = dynamic(() => import("./NewYieldFixedTable"), {
  ssr: false
});

export const YieldFlexibleTable = dynamic(() => import("./YieldFlexibleTable"), {
  ssr: false
});
export const YieldStakingTable = dynamic(() => import("./YieldStakingTable"), {
  ssr: false
});
