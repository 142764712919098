import React, { memo, useMemo } from "react";
import { i18nUtil } from "@aspen/libs";
import { IModalResultProps, ModalResult } from "../modalResult";

interface IProps extends IModalResultProps {
  visible: boolean;
  handleCancel: () => void;
  handleOk: () => void;
  attendance: number;
}

const ModalRegisterSuccessView = (props: IProps) => {
  const intl = i18nUtil.t();
  const { visible, handleCancel, handleOk, attendance } = props;
  const resultList = useMemo(
    () => [
      {
        label: "Attendance",
        value: attendance
      }
    ],
    [attendance]
  );
  return (
    <ModalResult
      {...props}
      open={visible}
      resultType={"success"}
      list={resultList}
      title={intl["event.detail.book.success"]}
      onCancel={handleCancel}
      onOk={handleOk}
    />
  );
};

const ModalRegisterSuccess = memo(ModalRegisterSuccessView);
export { ModalRegisterSuccess };
