"use client";
import React, { useState, useEffect, memo } from "react";
import { Tooltip, Spin } from "antd";
import { IAssetSituational, IAssetSituationalData } from "@aspen/model";
import { getAssetsSituational } from "@aspen/services";
import styles from "./TotalAssetsLine.module.less";
import dayjs from "dayjs";
import { LeftOutlined, RightOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  decimalPointNoMoreX,
  numberToThousands,
  getSpecifiedDate,
  transformDate,
  i18nUtil,
  reportEvent,
  BENCHMARK_CURRENCY_SYMBOL,
  GA_EVENT_NAME,
  REFERENCE_CURRENCY
} from "@aspen/libs";
import dynamic from "next/dynamic";
import { useAppSelector } from "@aspen/store";
import { assetsLineData } from "@aspen/store";

interface IProps {
  lineHeight?: number; // 折线图的高度
  showTips?: boolean;
  totalAmount: number | string;
}
const LineChart = dynamic(() => import("../../charts/LineChart"), {
  loading: () => <></>
});

export const TotalAssetsLine: React.FC<IProps> = memo((props) => {
  const intl = i18nUtil.t();
  const title: string = i18nUtil.formatMessage(
    { id: "overview.assetSituational.title" },
    { currency: REFERENCE_CURRENCY }
  );
  const tipsYesterday: string = intl["assets.tips.yesterday"];

  const [loading, setLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [dataInfo, setDataInfo] = useState<IAssetSituationalData[]>();
  const lineData = useAppSelector(assetsLineData);

  useEffect(() => {
    if (!lineData) {
      setLoading(true);
    }
    setData(lineData);
  }, [lineData]);

  const getData = (offset: number) => {
    const params = {
      type: 0, //day 0, week 1, month 2, year 3 目前默认只显示0
      offset: offset,
      limit: 30
    };
    getAssetsSituational(params)
      .then((res) => {
        if (res?.code == "0") {
          setData(res?.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setData = (data: IAssetSituational) => {
    let info = data?.rows && [...data.rows].reverse();
    // @ts-ignore
    setTotalCount(data?.totalCount);
    // @ts-ignore
    if (data?.totalCount > 1) {
      setDataInfo(info ?? []);
    } else {
      // @ts-ignore
      resetEmptyData(info, data?.totalCount);
    }

    setTimeout(() => {
      setLoading(false);
    }, 10);
  };

  // 处理当天刚入金 但是没有数据点的问题
  // 只需要currenWalletInfo.totalAmount数据，所以从父组建传递即可
  const resetEmptyData = (info: IAssetSituationalData[], totalCount: number) => {
    const totalAmount = props?.totalAmount ?? 0;
    const now = transformDate(new Date().getTime());
    const timeBefore = getSpecifiedDate(-1).split(" ")[0];
    const timeBeforeTwo = getSpecifiedDate(-2).split(" ")[0];
    if (totalCount === 1) {
      // 如果有一个点 在该点前打一个0点
      info.unshift({
        asset: 0,
        assetDate: timeBeforeTwo
      });
      setDataInfo(info);
    } else {
      // 如果有没有点，打昨天一个0点，今天入金的资产点
      setDataInfo([
        {
          asset: 0,
          assetDate: timeBefore
        },
        {
          asset: Number(totalAmount),
          assetDate: now
        }
      ]);
    }
  };

  // 修改线图时间段 0为上一时间段 1为下一时间段
  const handleChangeDate = (type: number) => {
    if (isItPossibleToClick(type)) {
      if (type === 0) {
        setOffset(offset + 30);
        getData(offset + 30);
      } else {
        setOffset(offset - 30);
        getData(offset - 30);
      }
    }
  };

  // 处理右上角时间显示
  const getDateRange = () => {
    if (!dataInfo?.length) return;
    const startDate =
      dataInfo[0].assetDate.split("-")[0] + "-" + dataInfo[0].assetDate.split("-")[1];
    const endDate =
      dataInfo[dataInfo.length - 1].assetDate.split("-")[0] +
      "-" +
      dataInfo[dataInfo.length - 1].assetDate.split("-")[1];
    if (startDate == endDate) {
      return startDate;
    } else {
      return `${startDate}~${endDate}`;
    }
  };

  // 修改时间按钮是否可以点击 0为上一时间段 1为下一时间段
  const isItPossibleToClick = (type: number) => {
    if (totalCount > 0) {
      if (type === 0) {
        if (totalCount > offset + 30) {
          return true;
        }
      } else {
        if (offset > 0) {
          return true;
        }
      }
    }
  };

  return (
    <Spin spinning={loading}>
      <div className={styles.container}>
        <div className={styles.topArea}>
          <div>
            <span className={styles.title}>{title}</span>
            {props.showTips && (
              <Tooltip title={tipsYesterday}>
                <InfoCircleOutlined style={{ paddingLeft: "10px", fontSize: "12px" }} />
              </Tooltip>
            )}
          </div>
          <div className={styles.actionArea}>
            <div
              className={styles.actionBtn + " " + (isItPossibleToClick(0) ? "" : styles.disabled)}
              onClick={() => {
                handleChangeDate(0);
                reportEvent({
                  moduleName: GA_EVENT_NAME.home.totalAssetsLine,
                  detailParams: { action: "dateToPrev" }
                });
              }}>
              <LeftOutlined className="pointerNone" />
            </div>
            <span className={styles.nowDate}>
              {getDateRange() ? getDateRange() : dayjs().format("YYYY-MM")}
            </span>
            <div
              data-id="totalAssetsLine-dateToNext"
              className={styles.actionBtn + " " + (isItPossibleToClick(1) ? "" : styles.disabled)}
              onClick={() => {
                handleChangeDate(1);
                reportEvent({
                  moduleName: GA_EVENT_NAME.home.totalAssetsLine,
                  detailParams: { action: "dateToNext" }
                });
              }}>
              <RightOutlined className="pointerNone" />
            </div>
          </div>
        </div>
        <div className={styles.lineArea} style={{ height: props.lineHeight }}>
          <LineChart
            xField={"assetDate"}
            yField={"asset"}
            // @ts-ignore
            list={dataInfo}
            themeColor={"#F17E00"}
            areaStartColor={"#F17E00"}
            areaEndColor={"#1F212F"}
            xTickCount={8}
            yAxisFormatter={(text: string) => {
              return numberToThousands(decimalPointNoMoreX(text, 2));
            }}
            tooltipValueFormatter={(text) => {
              return `${BENCHMARK_CURRENCY_SYMBOL} ${numberToThousands(
                decimalPointNoMoreX(text, 2)
              )}`;
            }}
          />
        </div>
      </div>
    </Spin>
  );
});
