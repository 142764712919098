"use client";
import React, { useEffect, useState } from "react";
import { i18nUtil } from "@aspen/libs";
import Image from "next/image";
import styles from "../login/safetyVerify/SafetyVerify.module.less";
import { Button, Checkbox } from "antd";
import { IVerificationType } from "@aspen/model";
import { message } from "@aspen/ui";
import { VERIFY_TYPE } from "@aspen/model";

const email = require("@aspen/assets/images/profile/email.webp");
const google = require("@aspen/assets/images/profile/google.webp");
const phone = require("@aspen/assets/images/profile/phone.webp");

type IProps = {
  verifyType?: string;
  supportValidChannel: IVerificationType[];
  currentMode: IVerificationType[];
  confirm: (type: IVerificationType[]) => void;
  onCancel: () => void;
};

const images = {
  [VERIFY_TYPE.email]: {
    src: email,
    title: "",
    width: 18,
    height: 13
  },
  [VERIFY_TYPE.phone]: {
    src: phone,
    width: 12,
    height: 18
  },
  [VERIFY_TYPE.google]: {
    src: google,
    width: 13,
    height: 13
  }
};

const SwitchVerifyMultiList = (props: IProps) => {
  const intl = i18nUtil.t();
  const { supportValidChannel, currentMode, confirm, onCancel } = props;
  const [selectedVerifyChannels, setSelectedVerifyChannels] = useState<IVerificationType[]>(
    currentMode as IVerificationType[]
  );

  useEffect(() => {
    setSelectedVerifyChannels(selectedVerifyChannels);
  }, [supportValidChannel, currentMode, selectedVerifyChannels]);

  const handleCheckStatus = (type: IVerificationType) => {
    return selectedVerifyChannels.indexOf(type) != -1;
  };

  const checkMethod = (item: IVerificationType, index: number) => {
    let _selectedVerifyChannels = selectedVerifyChannels.slice();
    const targetPos = _selectedVerifyChannels.indexOf(item);
    if (_selectedVerifyChannels.indexOf(item) != -1 && _selectedVerifyChannels.length > 1) {
      _selectedVerifyChannels.splice(targetPos, 1);
      setSelectedVerifyChannels(_selectedVerifyChannels);
    } else {
      if (_selectedVerifyChannels.length === 2) {
        _selectedVerifyChannels.splice(targetPos, 1, item);
        setSelectedVerifyChannels(_selectedVerifyChannels);
      } else {
        setSelectedVerifyChannels(_selectedVerifyChannels.concat(item));
      }
    }
  };

  const handleConfirm = () => {
    if (selectedVerifyChannels.length === 2) {
      confirm && confirm(selectedVerifyChannels);
    } else {
      const msg = intl["select.verification.type.multi.content"];
      message.error(msg);
    }
  };

  return (
    <>
      {supportValidChannel.map((key, index) => {
        return (
          <div key={key} className={styles.singleItem}>
            <div className={styles.flexRow}>
              <div className={styles.imgBox}>
                <Image
                  unoptimized
                  alt=""
                  src={images[key]?.src}
                  width={images[key]?.width}
                  height={images[key]?.height}
                />
              </div>
              <span className={styles.verifyModalContent}>
                {intl[`verification.method.${key}`]}
              </span>
            </div>
            <Checkbox
              key={index}
              checked={handleCheckStatus(key)}
              style={{ marginRight: 24 }}
              onChange={(e) => {
                checkMethod(key, index);
              }}
            />
          </div>
        );
      })}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/* TODO，@田世杰 */}
        {/* @ts-ignore */}
        <Button style={{ width: "45%", height: 45 }} type="ghost" onClick={onCancel}>
          {intl["button.cancel"]}
        </Button>
        <Button style={{ width: "45%", height: 45 }} type="primary" onClick={handleConfirm}>
          {intl["button.confirm"]}
        </Button>
      </div>
    </>
  );
};

export default SwitchVerifyMultiList;
