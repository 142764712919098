import axiosInstance from "../http";
import { saasApi as api } from "./api";
import { saasServer, SAAS_TOKEN, SAAS_TENANT_ID, getTenantStorageKey, isClient } from "@aspen/libs";
import {
  IRes,
  IReqSaasInvestmentList,
  IReqSaasLogin,
  IReqSaasNotAuthCode,
  IReqSaasSubscribeVenture,
  IReqSaasVentureList,
  IReqSaasVentureDetail,
  IResSaasLogin,
  IResSaasVenture,
  IResSaasWallet,
  IResSaasStrategy,
  IReqSaasStrategyList,
  IReqSaasInterestVenture,
  AsInformationQueryRequest,
  RAsInformationVO,
  IReqSaasInvestorPortalDetail,
  IReqSaasProductUpdates,
  IReqSaasMarkReadInvestorPortal,
  RListInformationUnreadVO,
  IReqSaasUnread,
  RTokenPageAsInformationVO,
  ExpertsQueryRequest,
  RTokenPageAuthorCustomerVO,
  IReqContactInfo,
  IReqSaasWalletReq
} from "@aspen/model";

const getDefaultConfig = () => ({
  baseURL: saasServer,
  headers: {
    "tenant-id":
      (typeof window != "undefined" && localStorage.getItem(getTenantStorageKey(SAAS_TENANT_ID))) ??
      1,
    jwtToken: (typeof window != "undefined" && localStorage.getItem(SAAS_TOKEN)) ?? ""
  }
});

/**
 * @description:  获取租户信息（未登录）
 * @params string
 * @return {Promise}
 */
export const saasGetTenantInfo = (domainName: string): Promise<IRes> => {
  return axiosInstance
    .get(api.saas.tenant.info, {
      params: { domainName },
      ...getDefaultConfig()
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 用户登录
 * @params {IReqSaasLogin}
 * @return {Promise}
 */
export const saasLogin = (params: IReqSaasLogin): Promise<IResSaasLogin> => {
  return axiosInstance.post(api.saas.auth.login, params, { ...getDefaultConfig() }).then((res) => {
    return res?.data;
  });
};

/**
 * @description: 获取邮箱验证码（未登录）
 * @params {IReqSaasNotAuthCode}
 * @return {Promise}
 */
export const saasGetNotAuthEmailCode = (params: IReqSaasNotAuthCode): Promise<IRes> => {
  return axiosInstance
    .get(api.saas.auth.getEmailCode.replace(/{email}/, params.email), {
      params,
      ...getDefaultConfig()
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 用户登出
 * @params {}
 * @return {Promise}
 */
export const saasLogout = (): Promise<IResSaasLogin> => {
  return axiosInstance
    .delete(api.saas.auth.logout, {
      ...getDefaultConfig()
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: venture列表
 * @params {IReqSaasVentureList}
 * @return {Promise}
 */
export const getSaasVentureList = (params: IReqSaasVentureList): Promise<IResSaasVenture> => {
  return axiosInstance
    .get(api.saas.venture.products, {
      params,
      ...getDefaultConfig()
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: venture收藏列表
 * @params {IReqSaasVentureList}
 * @return {Promise}
 */
export const getSaasInterestVentureList = (
  params: IReqSaasVentureList
): Promise<IResSaasVenture> => {
  return axiosInstance
    .get(api.saas.venture.interestProducts, {
      params,
      ...getDefaultConfig()
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: venture详情
 * @params {IReqSaasVentureDetail}
 * @return {Promise}
 */
export const getSaasVentureDetail = (params: IReqSaasVentureDetail): Promise<IRes> => {
  return axiosInstance
    .get(api.saas.venture.productDetail.replace(/{id}/, params.productId), {
      ...getDefaultConfig()
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: venture申购意向提交
 * @params {IReqSaasSubscribeVenture}
 * @return {Promise}
 */
export const saasSubscribeVenture = (params: IReqSaasSubscribeVenture): Promise<IResSaasLogin> => {
  return axiosInstance
    .post(api.saas.venture.subscription, params, { ...getDefaultConfig() })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: venture收藏
 * @params {IReqSaasInterestVenture}
 * @return {Promise}
 */
export const saasInterestVenture = (params: IReqSaasInterestVenture): Promise<IResSaasLogin> => {
  return axiosInstance
    .post(api.saas.venture.interest, params, { ...getDefaultConfig() })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: venture赎回意向提交
 * @params {IReqSaasSubscribeVenture}
 * @return {Promise}
 */
export const saasRedeemVenture = (params: IReqSaasSubscribeVenture): Promise<IResSaasLogin> => {
  return axiosInstance
    .post(api.saas.venture.redemption, params, { ...getDefaultConfig() })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: venture investment列表
 * @params {IReqSaasInvestmentList}
 * @return {Promise}
 */
export const getSaasVentureInvestmentList = (
  params: IReqSaasInvestmentList
): Promise<IResSaasVenture> => {
  return axiosInstance
    .get(api.saas.venture.records, {
      params,
      ...getDefaultConfig()
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: strategy列表
 * @params {IReqSaasStrategyList}
 * @return {Promise}
 */
export const getSaasStrategyList = (params: IReqSaasStrategyList): Promise<IResSaasStrategy> => {
  return axiosInstance
    .get(api.saas.strategy.products, {
      params,
      ...getDefaultConfig()
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: strategy详情
 * @params {IReqSaasVentureDetail}
 * @return {Promise}
 */
export const getSaasStrategyDetail = (params: IReqSaasVentureDetail): Promise<IRes> => {
  return axiosInstance
    .get(api.saas.strategy.productDetail.replace(/{id}/, params.productId), {
      ...getDefaultConfig()
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: strategy申购意向提交
 * @params {IReqSaasSubscribeVenture}
 * @return {Promise}
 */
export const saasSubscribeStrategy = (params: IReqSaasSubscribeVenture): Promise<IResSaasLogin> => {
  return axiosInstance
    .post(api.saas.strategy.subscription, params, { ...getDefaultConfig() })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: strategy赎回意向提交
 * @params {IReqSaasSubscribeVenture}
 * @return {Promise}
 */
export const saasRedeemStrategy = (params: IReqSaasSubscribeVenture): Promise<IResSaasLogin> => {
  return axiosInstance
    .post(api.saas.strategy.redemption, params, { ...getDefaultConfig() })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: strategy investment列表
 * @params {IReqSaasInvestmentList}
 * @return {Promise}
 */
export const getSaasStrategyInvestmentList = (
  params: IReqSaasInvestmentList
): Promise<IResSaasVenture> => {
  return axiosInstance
    .get(api.saas.strategy.records, {
      params,
      ...getDefaultConfig()
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: walletInfo
 * @params {}
 * @return {Promise}
 */
export const getSaasWalletInfo = (params: IReqSaasWalletReq): Promise<IResSaasWallet> => {
  return axiosInstance
    .get(api.saas.customer.wallet, {
      params,
      ...getDefaultConfig()
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: URL加签
 * @params {string}
 * @return {Promise}
 */
export const getSaasFileSignature = (filePath: string): Promise<IResSaasWallet> => {
  return axiosInstance
    .get(api.saas.customer.signature, {
      params: { filePath },
      ...getDefaultConfig()
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: information信息
 * @params {AsInformationQueryRequest}
 * @return {Promise}
 */
export const getSaasInvestorPortal = (
  params: AsInformationQueryRequest
): Promise<RTokenPageAsInformationVO> => {
  return axiosInstance
    .get(api.saas.investor.list.replace(/{informationType}/, params.informationType), {
      params,
      ...getDefaultConfig()
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 资讯标记已读
 * @params {IReqSaasMarkReadInvestorPortal}
 * @return {Promise}
 */
export const saasMarkReadInvestorPortal = (
  params: IReqSaasMarkReadInvestorPortal
): Promise<IRes> => {
  return axiosInstance
    .post(api.saas.investor.markRead.replace(/{informationType}/, params.informationType), params, {
      ...getDefaultConfig()
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 获取指定资讯详情
 * @params {IReqSaasInvestorPortalDetail}
 * @return {Promise}
 */
export const getSaasInvestorPortalDetail = (
  params: IReqSaasInvestorPortalDetail
): Promise<RAsInformationVO> => {
  return axiosInstance
    .get(api.saas.investor.detail.replace(/{id}/, params.id), {
      params,
      ...getDefaultConfig()
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 获取指定产品关联的项目进展列表
 * @params {IReqSaasProductUpdates}
 * @return {Promise}
 */
export const getSaasProductUpdates = (
  params: IReqSaasProductUpdates
): Promise<RTokenPageAsInformationVO> => {
  return axiosInstance
    .get(api.saas.investor.productUpdates.replace(/{productId}/, params.productId), {
      params,
      ...getDefaultConfig()
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 专栏作家列表（精选、全部）
 * @params {ExpertsQueryRequest}
 * @return {Promise}
 */
export const getSaasExperts = (
  params: ExpertsQueryRequest
): Promise<RTokenPageAuthorCustomerVO> => {
  return axiosInstance
    .get(api.saas.investor.experts, {
      params,
      ...getDefaultConfig()
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 专栏作家详情
 * @params {id:string}
 * @return {Promise}
 */
export const getSaasExpertsDetail = (id: string): Promise<RTokenPageAuthorCustomerVO> => {
  return axiosInstance
    .get(api.saas.investor.expertsDetail.replace(/{id}/, id), {
      ...getDefaultConfig()
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 查询指定类型有持仓产品的资讯未读标记
 * @params IReqSaasUnread
 * @return {Promise}
 */
export const getSaasUnreadInvestorPortal = (
  params: IReqSaasUnread
): Promise<RListInformationUnreadVO> => {
  return axiosInstance
    .get(api.saas.investor.unread, {
      params,
      ...getDefaultConfig()
    })
    .then((res) => {
      return res?.data;
    });
};

/**
 * @description: 提交Contact信息
 * @params IReqContactInfo
 * @return {Promise}
 */
export const submitContactInfo = (params: IReqContactInfo): Promise<IRes> => {
  return axiosInstance
    .post(api.saas.customer.submitContactInfo, params, {
      ...getDefaultConfig()
    })
    .then((res) => {
      return res?.data;
    });
};
