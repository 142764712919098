import { IRes } from "@aspen/model/index";

type TCallbackRes = {
  success: IRes[];
  errors: any[];
};

export function createMultiRequest(
  tasks: Promise<IRes | any>[],
  pool: number,
  callback: (res: TCallbackRes) => void
) {
  class TaskQueue {
    private queue: Promise<IRes | any>[] = [];
    private runningTask: number = 0;
    private results: IRes[] = [];
    private errors: any[] = [];
    pushTask(task: Promise<IRes | any>) {
      this.queue.push(task);
      this.next();
    }
    next() {
      while (this.runningTask < pool && this.queue.length) {
        // 为了按顺序返回来匹配对应的result 记录task进入时的index
        let old_index = this.runningTask;
        this.runningTask++;
        let task = this.queue.shift();
        task &&
          task
            .then((res: IRes) => {
              if (res?.code == "0") {
                this.results[old_index] = res;
              } else {
                this.errors.push(res);
              }
            })
            .catch((err: any) => {
              this.errors.push(err);
            })
            .finally(() => {
              this.runningTask--;
              this.next();
            });
      }
      if (this.runningTask === 0) callback({ success: this.results, errors: this.errors });
    }
  }
  let T = new TaskQueue();
  tasks.forEach((task) => T.pushTask(task));
}
