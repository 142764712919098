import type { ReactChild } from "react";
import React from "react";
import type { ModalProps } from "antd";
import { ModalBase } from "@aspen/ui/modalBase";
import { i18nUtil } from "@aspen/libs";

interface ModalBaseProps extends ModalProps {
  visible: boolean;
  title?: string;
  content: string;
  buttonType?: "default" | "only";
  children?: ReactChild;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ModalConfirm: React.FC<ModalBaseProps> = (props) => {
  const intl = i18nUtil.t();
  const { visible, content, buttonType, title, onConfirm, onCancel } = props;
  return (
    <ModalBase
      buttonType={buttonType || "default"}
      okText={<span>{intl["button.confirm"]}</span>}
      open={visible}
      title={title}
      onOk={onConfirm}
      maskClosable={false}
      onCancel={onCancel}>
      <div>{content}</div>
    </ModalBase>
  );
};
