import dynamic from "next/dynamic";
export const ModalAddAddressOrigin = dynamic(() => import("./ModalAddAddressOrigin"), {
  ssr: false
});

export const ModalSelectAddress = dynamic(() => import("./ModalSelectAddress"), {
  ssr: false
});

export const WhitelistAlert = dynamic(() => import("./WhitelistAlert"), {
  ssr: false
});
