import dynamic from "next/dynamic";
export const SpotAccountTable = dynamic(() => import("./SpotAccountTable"), {
  ssr: false
});

export const StrategyTable = dynamic(() => import("./StrategyTable"), {
  ssr: false
});

export const StructuredTable = dynamic(() => import("./StructuredTable"), {
  ssr: false
});

export const VentureTable = dynamic(() => import("./VentureTable"), {
  ssr: false
});

export const YieldAccountTable = dynamic(() => import("./YieldAccountTable"), {
  ssr: false
});
