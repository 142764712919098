"use client";
import React, { memo, useEffect, useMemo, useState } from "react";
import styles from "./PhoneInput.module.less";
import type { FormInstance } from "antd";
import { Form } from "antd";
import { CountriesForPhone, DEFAULT_PHONE_AFFIX, i18nUtil, REG_EXP_NUMBER_0_9 } from "@aspen/libs";
import CountrySelect from "@aspen/ui/countrySelect/CountrySelect";
import clsx from "clsx";
import { WithKYCInfo } from "@aspen/ui";
import type { IkycInfo } from "@aspen/model";
import { CommonLabelInput } from "@aspen/ui/commonLabelInput";

interface IProps {
  countrySelectStyle?: string;
  inputBoxStyle?: string;
  label?: string;
  viewKycPhone?: boolean;
  kycInfo?: IkycInfo;
  form?: FormInstance;
  onBlurAction?: () => void;
  setAccountName: (accountName) => void;
  setCountryCode: (countryCode) => void;
  setNationalNumber: (nationalNumber) => void;
}

const PhoneInputView: React.FC<IProps> = memo((props: IProps) => {
  const {
    countrySelectStyle,
    inputBoxStyle,
    form,
    label,
    setAccountName,
    setCountryCode,
    setNationalNumber,
    kycInfo,
    viewKycPhone = false,
    onBlurAction
  } = props;
  const intl = i18nUtil.t();
  const initValue = useMemo(() => {
    if (!viewKycPhone) {
      return null;
    }
    const initCountry = kycInfo?.phone?.split(" ")?.[0];
    const initPhone = kycInfo?.phone?.split(" ")?.[1];
    const findCountry = CountriesForPhone.find((item) => item.telephoneCode === initCountry);
    if (!findCountry) {
      return null;
    }
    return {
      country: initCountry,
      phone: initPhone
    };
  }, [kycInfo?.phone]);
  useEffect(() => {
    if (viewKycPhone) {
      if (initValue?.phone && initValue?.country) {
        setPhone(initValue?.phone);
        setCountryCode?.(initValue?.country);
        setNationalNumber?.(initValue?.phone);
        setAccountName(`${initValue?.country}${initValue?.phone}`);
        form?.setFieldsValue({ accountName: `${initValue?.country}${initValue?.phone}` });
      }
    }
  }, [initValue]);
  const [phone, setPhone] = useState("");
  const [phoneAffix, setPhoneAffix] = useState(initValue?.country ?? DEFAULT_PHONE_AFFIX);
  const onCountryChange = (country) => {
    const affix = country.split("_")?.[0];
    setPhoneAffix(affix);
    setCountryCode?.(affix);
    setNationalNumber?.(phone);
    setAccountName(`${affix}${phone}`);
    form?.setFieldsValue({ accountName: `${affix}${phone}` });
    onBlurAction && onBlurAction();
  };

  const validPhone = (phone, affix) => {
    if (phone === "") {
      return true;
    }
    if (!REG_EXP_NUMBER_0_9.test(phone)) {
      return false;
    }
    if (affix === "+86") {
      // 从其他国家切换到+86，如果已输入超出11位，截取11位显示
      if (phone.length > 11) {
        const phoneSub = phone.substring(0, 11);
        setPhone(phoneSub);
        setCountryCode?.(phoneAffix);
        setNationalNumber?.(phoneSub);
        setAccountName(`${phoneAffix}${phoneSub}`);
        form?.setFieldsValue({ accountName: `${phoneAffix}${phoneSub}` });
        return false;
      }
    }
    return true;
  };

  const onChange = (value) => {
    const isPass = validPhone(value, phoneAffix);
    if (!isPass) {
      return;
    }
    setPhone(value);
    setCountryCode?.(phoneAffix);
    setNationalNumber?.(value);
    setAccountName(`${phoneAffix}${value}`);
    form?.setFieldsValue({ accountName: `${phoneAffix}${value}` });
  };
  return (
    <div className={styles.container}>
      <Form.Item noStyle>
        <div className={clsx(styles.countrySelect, countrySelectStyle)}>
          <CountrySelect
            countrySelectStyle={countrySelectStyle}
            defaultValue={initValue?.country}
            onChange={onCountryChange}
          />
        </div>
      </Form.Item>
      <Form.Item noStyle>
        <div className={styles.phoneInput}>
          <CommonLabelInput
            value={phone}
            isTop={!!phone}
            inputBoxStyle={clsx(styles.inputBoxStyle, inputBoxStyle)}
            label={label ?? intl["login.phone"]}
            defaultValue={initValue?.phone}
            placeholder={intl["register_input_mobile"]}
            onChange={(e) => onChange(e.target.value)}
            onBlur={onBlurAction}
          />
        </div>
      </Form.Item>
    </div>
  );
});
export const PhoneInput = WithKYCInfo(PhoneInputView);
