import dynamic from "next/dynamic";

export const CMSDashboard = dynamic(() => import("./CMSDashboard"), {
  ssr: false
});
export const DashboardTotal = dynamic(() => import("./dashboard/DashboardTotal"), {
  ssr: false
});
export const TotalClient = dynamic(() => import("./dashboard/TotalClient"), {
  ssr: false
});
export const TotalAum = dynamic(() => import("./dashboard/TotalAum"), {
  ssr: false
});
export const TotalRevenue = dynamic(() => import("./dashboard/TotalRevenue"), {
  ssr: false
});
export const DashboardAum = dynamic(() => import("./dashboard/DashboardAum"), {
  ssr: false
});
export const DashboardPlatform = dynamic(() => import("./dashboard/DashboardPlatform"), {
  ssr: false
});
export const PlatformLatest = dynamic(() => import("./dashboard/PlatformLatest"), {
  ssr: false
});
export const PlatFormItem = dynamic(() => import("./dashboard/PlatFormItem"), {
  ssr: false
});
export const DashboardRecent = dynamic(() => import("./dashboard/DashboardRecent"), {
  ssr: false
});

export const ModalRecentDetail = dynamic(() => import("./dashboard/ModalRecentDetail"), {
  ssr: false
});

export const CMSCustomer = dynamic(() => import("./CMSCustomer"), {
  ssr: false
});

export const FilterAndSortList = dynamic(() => import("./customer/FilterAndSortList"), {
  ssr: false
});

export const CustomerList = dynamic(() => import("./customer/CustomerList"), {
  ssr: false
});

export const BasicInformation = dynamic(() => import("./customerDetail/BasicInformation"), {
  ssr: false
});
export const Wallet = dynamic(() => import("./customerDetail/Wallet"), {
  ssr: false
});
export const AssetAllocation = dynamic(() => import("./customerDetail/AssetAllocation"), {
  ssr: false
});
export const CMSAssetDistribution = dynamic(() => import("./customerDetail/AssetDistribution"), {
  ssr: false
});
export const AUM = dynamic(() => import("./customerDetail/AUM"), {
  ssr: false
});
export const RecentActivity = dynamic(() => import("./customerDetail/RecentActivity"), {
  ssr: false
});
export const RecentSessions = dynamic(() => import("./customerDetail/RecentSessions"), {
  ssr: false
});
export const SessionsHistory = dynamic(() => import("./customerDetail/SessionsHistory"), {
  ssr: false
});

export const Operation = dynamic(() => import("./Operation"), {
  ssr: false
});

export const PiePlot = dynamic(() => import("./plots/PiePlot"), {
  ssr: false
});

export const ColumnPlot = dynamic(() => import("./plots/ColumnPlot"), {
  ssr: false
});

export const TotalClientPie = dynamic(() => import("./plots/TotalClientPie"), {
  ssr: false
});

export const CanvasPie = dynamic(() => import("./plots/CanvasPie"), {
  ssr: false
});

export const CMSProduct = dynamic(() => import("./CMSProduct"), {
  ssr: false
});

export const Venture = dynamic(() => import("./product/Venture"), {
  ssr: false
});

export const Staking = dynamic(() => import("./product/Staking"), {
  ssr: false
});

export const Trade = dynamic(() => import("./product/Trade"), {
  ssr: false
});

export const Structured = dynamic(() => import("./product/Structured"), {
  ssr: false
});

export const ProductDashboardItem = dynamic(
  () => import("./product/productDetail/ProductDashboardItem"),
  {
    ssr: false
  }
);
export const ProductDashboardCard = dynamic(
  () => import("./product/productDetail/ProductDashboardCard"),
  {
    ssr: false
  }
);
export const ProductDashboardMonthlyVisitors = dynamic(
  () => import("./product/productDetail/ProductDashboardMonthlyVisitors"),
  {
    ssr: false
  }
);
export const VentureProductListItem = dynamic(
  () => import("./product/productDetail/VentureProductListItem"),
  {
    ssr: false
  }
);
export const StakingProductListItem = dynamic(
  () => import("./product/productDetail/StakingProductListItem"),
  {
    ssr: false
  }
);
export const VenturePotentialTable = dynamic(
  () => import("./product/table/VenturePotentialTable"),
  {
    ssr: false
  }
);
export const VentureRecentTable = dynamic(() => import("./product/table/VentureRecentTable"), {
  ssr: false
});

export const VentureInterestedTable = dynamic(
  () => import("./product/table/VentureInterestedTable"),
  {
    ssr: false
  }
);

export const VentureSubscriptionTable = dynamic(
  () => import("./product/table/VentureSubscriptionTable"),
  {
    ssr: false
  }
);
export const TableSearch = dynamic(() => import("./product/table/TableSearch"), {
  ssr: false
});
export const StakingAUMColumnPlot = dynamic(
  () => import("./product/staking/StakingAUMColumnPlot"),
  {
    ssr: false
  }
);
export const StakingRecentActivitiesTable = dynamic(
  () => import("./product/staking/StakingRecentActivitiesTable"),
  {
    ssr: false
  }
);
export const StakingRecentTable = dynamic(() => import("./product/staking/StakingRecentTable"), {
  ssr: false
});
export const StakingPotentialTable = dynamic(
  () => import("./product/staking/StakingPotentialTable"),
  {
    ssr: false
  }
);
export const StakingSubscriptionTable = dynamic(
  () => import("./product/staking/StakingSubscriptionTable"),
  {
    ssr: false
  }
);

export const TradeColumnPlot = dynamic(() => import("./product/trade/TradeColumnPlot"), {
  ssr: false
});
export const TradeRecentOrdersTable = dynamic(
  () => import("./product/trade/TradeRecentOrdersTable"),
  {
    ssr: false
  }
);

export const StructuredColumnPlot = dynamic(
  () => import("./product/structured/StructuredColumnPlot"),
  {
    ssr: false
  }
);

export const StructuredRecentOrdersTable = dynamic(
  () => import("./product/structured/StructuredRecentOrdersTable"),
  {
    ssr: false
  }
);

export const ModalStructureOrderDetail = dynamic(
  () => import("./product/structured/ModalStructureOrderDetail"),
  {
    ssr: false
  }
);
