import React, { memo, useMemo, useState } from "react";
import { i18nUtil } from "@aspen/libs";
import { Counter, ModalBase } from "@aspen/ui";
import { Input } from "antd";

interface IProps {
  visible: boolean;
  loading?: boolean;
  handleCancel: () => void;
  handleOk: (num: number, guestName?: string) => void;
}

export const ModalRegisterView = (props: IProps) => {
  const intl = i18nUtil.t();
  const { visible, handleOk, handleCancel, loading = false } = props;
  const [num, setNum] = useState<number>(1);
  const [guestName, setGuestName] = useState<string>("");

  const confirmDisable = useMemo(() => {
    if (num > 1) {
      return !guestName;
    } else {
      return false;
    }
  }, [num, guestName]);
  return (
    <ModalBase
      title={intl["event.detail.book.title"]}
      centered
      maskClosable={false}
      okButtonProps={{ loading, disabled: confirmDisable }}
      open={visible}
      buttonType={"only"}
      okText={intl["button.confirm"]}
      onCancel={handleCancel}
      onOk={() => {
        handleOk(num, guestName);
      }}>
      <>
        <div className="flex-col">
          <div className="flex" style={{ alignItems: "flex-end" }}>
            <div>
              <p style={{ marginBottom: 8 }}>{intl["event.detail.book.attendance"]}</p>
              <Input
                style={{ marginRight: 9, width: 295 }}
                disabled={true}
                bordered={false}
                value={num}
                onChange={(e) => {
                  setNum(Number(e.target.value));
                }}
                placeholder={intl["placeholder.enter"]}
              />
            </div>
            <Counter
              onChange={(count) => {
                setNum(count);
              }}
            />
          </div>
          {num > 1 ? (
            <div style={{ marginTop: 16 }}>
              <p style={{ marginBottom: 8 }}>{intl["event.detail.book.names"]}</p>
              <Input
                maxLength={50}
                bordered={false}
                value={guestName}
                onChange={(e) => {
                  setGuestName(e.target.value);
                }}
                placeholder={intl["event.detail.book.names.holder"]}
              />
            </div>
          ) : null}
        </div>
      </>
    </ModalBase>
  );
};
const ModalRegister = memo(ModalRegisterView);
export { ModalRegister };
