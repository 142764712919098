/**
 * num start time 到 current 的时间差 单位/秒
 */
type callBack = (offset: number) => void;

/**
 * 这个类是用来进行倒计时了,每间隔一秒回调 current-start 的时间差
 */

class CountDownTimer {
  _onDoing: boolean = false;
  _startTime: number = 0;
  _endTime: number = 0;
  _diffTime: number = -1;
  _callBack: callBack | undefined;

  /**
   * 注意单位是毫秒
   * @param startTime
   * @param endTime
   */
  constructor(startTime: number, endTime: number) {
    this._startTime = startTime;
    this._endTime = endTime;
  }

  setCallBack = (callBack: callBack) => {
    this._callBack = callBack;
  };

  restTime = (startTime: number, endTime: number) => {
    this._startTime = startTime;
    this._endTime = endTime;
  };

  start = () => {
    if(this._startTime > this._endTime){
      return new Error ("Please check parameter:error in compare startTime and endTime");
    } else {
      this._onDoing = true;
      this._startFrame();
    }
  };

  stop = () => {
    this._onDoing = false;
  };

  isActive = () => {
    return this._onDoing;
  };

  _startFrame = () => {
    requestAnimationFrame(this._handleTimer);
  };
  _handleTimer = () => {
    if(this._startTime > this._endTime){
      return new Error ("Please check parameter:error in compare startTime and endTime");
    }
    if (!this._onDoing) {
      return;
    }
    const currentTime = new Date().getTime();

    const diffTime = parseInt((currentTime - this._startTime) / 1000 + "");
    if (diffTime != this._diffTime) {
      this._diffTime = diffTime;
      this._callBack && this._callBack(this._diffTime);
    }
    if (currentTime <= this._endTime) {
      this._startFrame();
    }
  };
}

export { CountDownTimer };
