export const operationApi = {
  operationCms: {
    client: "employee/client",
    aum: "employee/customer-aum-monthly",
    recent: "employee/customer-recent-activities",
    assetSummary: "employee/customer-asset-summary",
    assetSummaryDetail: "employee/customer-asset-summary/detail",
    sessionStatistics: "employee/customer-session-statistics",
    monitorTypes: "employee/business-monitor-types",
    walletAssets: "employee/wallet/info",
    assetAllocation: "employee/asset/positions/currencies",
    spotAccount: "employee/accounts/spot",
    flexibleAccount: "employee/accounts/yield",
    fixedAccount: "employee/finance/fixed-yield/contracts",
    stakingAccount: "employee/finance/staking/contracts",
    dualAccount: "employee/structured/dual/positions",
    accumulatorTableAccount: "employee/structured/accumulator/positions",
    snowballTableAccount: "employee/structured/snowball/positions",
    strategyAccount: "employee/accounts/strategy",
    ventureAccount: "employee/accounts/venture",
    sessionHistory: "employee/customer-session-details",
    sessionDetailStatistics: "employee/customer-session-detail-statistics-monthly",
    employees: "employee/list",
    ventureList: "employee/product/list-products",
    ventureRecentVisit: "employee/product/list-recent-visit",
    ventureInterested: "employee/product/ventures/interested",
    ventureSubscriptions: "employee/product/ventures/subscriptions",
    stakingSubscriptions: "employee/product/list-product-subscribes",
    ventureAumProportion: "employee/product/product_aum_proportion",
    ventureCustomerAumProportion: "employee/product/product_aum_proportion_visit_event",
    listProductBusiness: "employee/product/list-product-business",
    // 查询代理商下属客户Staking产品按月统计的Aum
    stakingAum: "employee/staking/aum",
    // 查询代理商下属客户操作Staking产品的事件数（按月统计）
    stakingEvent: "employee/staking/event",
    // 查询代理商下属客户Staking产品按月统计的持仓人数
    stakingSubscriber: "employee/staking/subscriber",
    // 分页查询用户交易订单记录
    tradeOrders: "employee/trade/orders",
    // 客户交易分析对比历史增量增幅等信息
    tradeAnalysis: "employee/trade/analysis",
    // 查询代理商下属客户按月统计的订单交易量
    tradeVolume: "employee/trade/volume",
    // 查询代理商下属客户按月统计的订单交易人数
    tradeTrader: "employee/trade/trader",
    // 查询代理商下属客户按月统计的订单交易事件数
    tradeEvent: "employee/trade/event",
    // 查询代理商下属客户按月统计的月末现货aum
    tradeAum: "employee/trade/aum",
    // 分页获取结构化产品订单
    structuredOrders: "employee/structured/orders",
    // 获取结构化产品交易量占比增幅等信息
    structuredAnalysis: "employee/structured/analysis",
    // 获取结构化产品列表
    structuredProducts: "employee/structured/products",
    // 按月统计的结构化理财产品持有人数
    structuredSubscriber: "employee/structured/subscriber",
    // 按月统计的结构化理财产品事件数
    structuredEvent: "employee/structured/event",
    // 按月统计的月末现货aum
    structuredAum: "employee/structured/aum"
  }
};
