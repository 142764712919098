import React, { useState, useEffect, useImperativeHandle, forwardRef, memo } from "react";
import { DatePicker } from "@aspen/ui";
import styles from "./DateRangePicker.module.less";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

interface IProps {
  optionalRange: number; // 可选日期范围
  changeCustomDate: (dateArr: Array<string>) => void;
}

export const DateRangePicker = memo(
  forwardRef((props: IProps, ref) => {
    useEffect(() => {}, []);
    const [dates, setDates] = useState(null);
    const [hackValue, setHackValue] = useState(null);
    const [value, setValue] = useState(null);
    const disabledDate = (current) => {
      if (!dates) {
        return false;
      }
      const tooLate = dates[0] && current.diff(dates[0], "days") > props.optionalRange;
      // @ts-ignore
      const tooEarly = dates[1] && dates[1].diff(current, "days") > props.optionalRange;
      const lastDay = current && current > dayjs().startOf("day");
      return !!tooEarly || !!tooLate || !!lastDay;
    };

    const onOpenChange = (open) => {
      if (open) {
        // @ts-ignore
        setHackValue([null, null]);
        // @ts-ignore
        setDates([null, null]);
      } else {
        setHackValue(null);
      }
    };

    const confirm = (val) => {
      if (val) {
        props.changeCustomDate([
          dayjs(val[0]).format("YYYY-MM-DD"),
          dayjs(val[1]).format("YYYY-MM-DD")
        ]);
        setValue(val);
      }
    };
    const delValue = () => {
      setHackValue(null);
      setValue(null);
    };
    useImperativeHandle(ref, () => ({
      delValue
    }));

    return (
      <RangePicker
        className={styles.rangePicker}
        value={hackValue || value}
        disabledDate={disabledDate}
        // @ts-ignore
        onCalendarChange={(val) => setDates(val)}
        onChange={(val) => confirm(val)}
        onOpenChange={onOpenChange}
      />
    );
  })
);
