"use client";
import React, { memo, useEffect, useMemo } from "react";
import { Modal, Spin } from "antd";
import { message } from "@aspen/ui/customMessage";
import formStyles from "@aspen/theme/Form.module.less";
import styles from "./index.module.less";
import { i18nUtil } from "@aspen/libs";
import { AccountInput, EmailInputGroup } from "@aspen/ui/commonInput";
import type { IReqSecurityVerifyHeader, IRes, IVerificationType } from "@aspen/model";
import { IBindingBusinessType } from "@aspen/model";
import { getCustomerConfigInfo, securityVerify } from "@aspen/services";
import { SafetyTips } from "@aspen/ui";

interface IProps {
  visible: boolean;
  businessType: IBindingBusinessType;
  confirm: () => void;
  cancel: () => void;
  relatedType: string;
  content: string;
}

/**
 *
 * @param props
 * @constructor
 * 该安全验证弹窗是手机相关的前置校验，目前涉及的业务场景有：绑定/换绑手机号。（必须要通过安全校验,调用securityVerify接口成功后才能进入业务操作流程。
 * 存在邮箱验证和谷歌验证
 */
export const ModalPhoneVerification = memo((props: IProps) => {
  const intl = i18nUtil.t();
  const { visible, relatedType, confirm, cancel, content } = props;
  const businessType = useMemo(() => {
    if (relatedType === "bind") {
      return IBindingBusinessType.bindPhone;
    }
    if (relatedType === "unBind") {
      return IBindingBusinessType.changeBind;
    }
    return IBindingBusinessType.changeBind;
  }, [relatedType]);
  const [
    {
      loading,
      confirmLoading,
      emailCode,
      googleCode,
      existGoogleVerify,
      changeVerifyMethod,
      supportVerificationType
    },
    setConfig
  ] = React.useState<{
    loading: boolean;
    confirmLoading: boolean;
    emailCode: string;
    googleCode: string;
    existGoogleVerify: boolean;
    changeVerifyMethod: boolean;
    supportVerificationType: IVerificationType[];
  }>({
    loading: false,
    confirmLoading: false,
    existGoogleVerify: false,
    changeVerifyMethod: false,
    emailCode: "",
    googleCode: "",
    supportVerificationType: []
  });
  const confirmDisabled = useMemo(() => {
    return !(emailCode || googleCode);
  }, [emailCode || googleCode]);

  useEffect(() => {
    getSupportedVerifyType();
  }, []);

  const getSupportedVerifyType = async () => {
    try {
      setLoading(true);
      const res: IRes = await getCustomerConfigInfo();
      setLoading(false);
      const supportValidChannels = res?.data?.supportValidChannels || [];
      setConfig((prevConfig) => ({
        ...prevConfig,
        supportVerificationType: supportValidChannels
      }));
      if (supportValidChannels.includes("google")) {
        setExistGoogleVerify(true);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const setCode = (type, code) => {
    setConfig((prevConfig) => ({ ...prevConfig, [type]: code }));
  };
  const setLoading = (flag) => {
    setConfig((prevConfig) => ({ ...prevConfig, loading: flag }));
  };
  const setConfirmLoading = (flag) => {
    setConfig((prevConfig) => ({ ...prevConfig, confirmLoading: flag }));
  };
  const setChangeVerifyMethod = (flag) => {
    setConfig((prevConfig) => ({ ...prevConfig, changeVerifyMethod: flag }));
  };
  const setExistGoogleVerify = (flag) => {
    setConfig((prevConfig) => ({ ...prevConfig, existGoogleVerify: flag }));
  };

  const handleOk = () => {
    setConfirmLoading(true);
    const headerParams: IReqSecurityVerifyHeader = {
      businessType
    };
    if (relatedType === "bind") {
      headerParams.googleCode = googleCode;
      headerParams.emailCode = emailCode;
    } else if (changeVerifyMethod) {
      headerParams.googleCode = googleCode;
    } else {
      headerParams.emailCode = emailCode;
    }
    securityVerify(headerParams)
      .then((res) => {
        if (res?.code === "0") {
          confirm && confirm();
        } else {
          message.error(intl[res?.msg] ?? res?.msg);
        }
      })
      .catch((err) => {
        message.error(err?.message);
      })
      .finally(() => {
        setConfirmLoading(false);
      });
  };
  const onCancel = () => {
    cancel && cancel();
  };
  const _renderChangeView = () => {
    return supportVerificationType.length > 1 && existGoogleVerify && relatedType === "change" ? (
      <div style={{ marginBottom: 20 }}>
        <span
          className={styles.changeTxt}
          onClick={() => {
            setChangeVerifyMethod(!changeVerifyMethod);
          }}>
          {changeVerifyMethod
            ? intl["verification.email.switch"]
            : intl["verification.authenticator.switch"]}
        </span>
      </div>
    ) : null;
  };

  const handleGoogleAccount = () => {
    if (
      existGoogleVerify &&
      (relatedType === "bind" || (relatedType === "change" && changeVerifyMethod))
    ) {
      return (
        <>
          <AccountInput
            label={intl["login.google.verify.code"]}
            onChangeTxt={(code) => {
              setCode("googleCode", code);
            }}
          />
          <span className={styles.tipsTxt}>{intl["login.verification.google.tips"]}</span>
        </>
      );
    }
  };

  const _renderEmailAccount = () => {
    if (existGoogleVerify && changeVerifyMethod) {
      return null;
    }
    return (
      <>
        <EmailInputGroup
          // @ts-ignore @赵靖
          businessType={businessType}
          label={intl["login.email.verify.code"]}
          onChangeTxt={(code) => {
            setCode("emailCode", code);
          }}
        />
      </>
    );
  };

  return (
    <Modal
      maskClosable={false}
      wrapClassName={formStyles.form}
      title={intl["verification.title"]}
      open={visible}
      confirmLoading={confirmLoading}
      onOk={handleOk}
      centered
      okText={intl["button.confirm"]}
      okButtonProps={{ disabled: confirmDisabled }}
      onCancel={onCancel}
      width={480}>
      <Spin spinning={loading}>
        <SafetyTips description={content} />
        {_renderEmailAccount()}
        {handleGoogleAccount()}
        {_renderChangeView()}
      </Spin>
    </Modal>
  );
});
