"use client";
import React from "react";
import Script from "next/script";
import { i18nUtil, formatBugsnagMessage, AppkeyAlicloud, isClient } from "@aspen/libs";
import { message } from "@aspen/ui";
import Bugsnag from "@bugsnag/js";

const NVCCaptcha = {
  init: (scene) => {
    if (isClient) {
      window.AWSC?.use("nvc", (state, module) => {
        // state标识状态，首次实例化会加载外部js，timeout为获取超时，loaded为已加载
        if (state === "timeout") {
          const intl = i18nUtil.t();
          message.error(intl["alicloud.loading.timeout.tip"]);

          console.log("alicloud nvc loading timeout");
          Bugsnag.notify(
            new Error(
              formatBugsnagMessage(
                "Alicloud sliding verification JS loading timeout",
                "Action-sliding-verification"
              )
            )
          );
        }

        // 初始化 调用module.init进行初始化
        window.nvc = module.init({
          appkey: AppkeyAlicloud,
          scene
        });
      });
    }
  },
  getVal: (callback) => {
    if (window.nvc?.getNVCValAsync) {
      window.nvc?.getNVCValAsync((nvcVal) => {
        if (location.origin.includes("beta")) {
          Bugsnag.notify(
            formatBugsnagMessage(
              `Alicloud nc data length: ${nvcVal.length},
                  data: ${nvcVal}`,
              "Action-Alicloud-verification"
            )
          );
        }
        callback(nvcVal);
      });
    } else {
      const intl = i18nUtil.t();
      message.error(intl["global.error.tip"]);

      Bugsnag.notify(
        new Error(
          formatBugsnagMessage("Login click no response, reason: window.nvc.getNVCValAsync error ")
        )
      );
    }
  }
};

function LoadAwscJS() {
  /**
   *  初始化阿里云人机校验无痕验证
   * scene 使用场景标识
   */
  const handleLoad = () => {
    NVCCaptcha.init("nvc_login_h5"); // 初始化阿里云无痕验证码
  };

  return <Script id="awsc-js" onLoad={handleLoad} src="https://g.alicdn.com/AWSC/AWSC/awsc.js" />;
}

export { LoadAwscJS, NVCCaptcha };
