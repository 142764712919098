import {
  SAAS_USERID,
  SAAS_TENANT_INFO,
  SAAS_TOKEN,
  SAAS_IS_LOGIN,
  SAAS_ACCOUNT,
  SAAS_PARAMS_DOMAIN_NAME,
  SAAS_CURRENT_DOMAIN,
  saasServer,
  SASS_PERMISSION_KEY,
  SaasIsRenderView
} from "../../config";
import { ISaasGenerateDataCacheKey } from "@aspen/model";
import qs from "qs";
import { isClient } from "../common";

const getTenantDomainName = () => {
  try {
    const configDomain = process.env.NEXT_PUBLIC_DOMAIN ?? "";
    const storageDomain = (isClient && localStorage.getItem(SAAS_CURRENT_DOMAIN))?.toString() ?? "";
    const search = (isClient && location?.search)?.toString() ?? "";
    const host = (isClient && location?.host)?.toString() ?? "";
    const queryParams = qs.parse(search);
    let domainName = "";
    // 优先从路由参数取domainName
    Object.keys(queryParams)?.map((key) => {
      if (key.indexOf(SAAS_PARAMS_DOMAIN_NAME) !== -1) {
        domainName = queryParams[key] as string;
      }
    });
    // 路由参数不包含domainName，取NEXT_PUBLIC_DOMAIN参数
    if (domainName === "") {
      domainName = configDomain;
    }
    // NEXT_PUBLIC_DOMAIN不包含domainName，从域名中取，类似tenant1.dev-asportal.co/取tenant1
    if (domainName === "") {
      const hostSplit = host?.split(".");
      domainName = hostSplit?.length > 0 ? hostSplit?.[0] : "";
    }
    // 域名不包含domainName，从storage中取
    if (domainName === "") {
      domainName = storageDomain ?? "";
    }
    return domainName;
  } catch (e) {
    return "";
  }
};

const getTenantStorageKey = (key: string) => {
  // 获取storage key的时候，domainName直接从localStorage里面取值
  const domain = isClient && localStorage.getItem(SAAS_CURRENT_DOMAIN);
  return `${saasServer}_${domain}_${key}`;
};

const getTenantInfo = () => {
  try {
    const tenantInfo =
      (isClient && localStorage.getItem(getTenantStorageKey(SAAS_TENANT_INFO))) ?? "";
    const tenant = JSON.parse(tenantInfo?.toString());
    return tenant ?? {};
  } catch (e) {
    return {};
  }
};

const clearLoginStorage = () => {
  if (!isClient) {
    return;
  }
  localStorage.removeItem(SAAS_TOKEN);
  localStorage.removeItem(SAAS_IS_LOGIN);
  localStorage.removeItem(SAAS_USERID);
  localStorage.removeItem(SAAS_ACCOUNT);
};

/**
 * @description: 生成key
 * @param param 生成key的参数，eg: type=0&offset=0&limit=30
 * 约定： 相同接口，相同参数，那么不管在任何页面，初始数据是可以共用的。
 * customerId 一般不需要传递，帮客户时注意测试，也可以自行传递
 * @return string
 */
const generateSaasDataCacheKey: (param: ISaasGenerateDataCacheKey) => string = ({
  params,
  api,
  language,
  customerId
}) => {
  const _language = language;
  const _customerId = customerId ? customerId : isClient && localStorage.getItem(SAAS_USERID);
  if (_language) {
    return `${saasServer}_cache_${_language}_${_customerId}_${api}_${params}`;
  } else {
    return `${saasServer}_cache_${_customerId}_${api}_${params}`;
  }
};
const isRenderView: (type: string, element: React.ReactElement) => React.ReactElement | null = (
  type,
  element
) => {
  switch (type) {
    case SaasIsRenderView.showPoweredByAspen:
      const isShow =
        isClient &&
        JSON.parse(localStorage.getItem(SASS_PERMISSION_KEY) as string)?.showPoweredByAspen;
      if (isShow) return element;
  }
  return null;
};

export {
  getTenantDomainName,
  getTenantStorageKey,
  getTenantInfo,
  clearLoginStorage,
  generateSaasDataCacheKey,
  isRenderView
};
