import dynamic from "next/dynamic";

export const ErrorTip = dynamic(() => import("./ErrorTip"), {
  ssr: false
});

export const ModalSwitchVerify = dynamic(() => import("./safetyVerify/ModalSwitchVerify"), {
  ssr: false
});

export const SafetyVerify = dynamic(() => import("./safetyVerify/SafetyVerify"), {
  ssr: false
});

export const LoginTabItem = dynamic(() => import("./LoginTabItem"), {
  ssr: false
});

export const RegisterStep1 = dynamic(() => import("./register/RegisterStep1"), {
  ssr: false
});

export const RegisterStep2 = dynamic(() => import("./register/RegisterStep2"), {
  ssr: false
});

export const RegisterStep3 = dynamic(() => import("./register/RegisterStep3"), {
  ssr: false
});

export const SwitchVerifyList = dynamic(() => import("./safetyVerify/SwitchVerifyList"), {
  ssr: false
});

export const LoginTabViewPortal = dynamic(() => import("./LoginTabViewPortal"), {
  ssr: false
});
export const ResetPasswordAll = dynamic(() => import("./ResetPasswordAll"), {
  ssr: false
});
export const RegisterSubAccount = dynamic(() => import("./RegisterSubAccount"), {
  ssr: false
});
export const AlicloudNCCaptcha = dynamic(() => import("./alicloudNCCaptcha"), {
  ssr: false
});
export * from "./LoadAwsc";
export * from "./LoginBaseHandle";
export * from "./LoginLayoutCMS";
export * from "./LoginLayoutPortal";
export * from "./LoginLayoutSAS";
