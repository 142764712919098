import React from "react";
import { ModalBase } from "@aspen/ui";
import { i18nUtil } from "@aspen/libs";
import SwitchVerifyMultiList from "./SwitchVerifyMultiList";
import { IVerificationType } from "@aspen/model";

type IProps = {
  visible: boolean;
  handleChangeVerifyMethod: (method: string) => void;
  onCancel: () => void;
  confirm: (type: IVerificationType[]) => void;
  supportValidChannel: IVerificationType[];
  currentMode: IVerificationType[];
};

export const ModalBusinessSwitchVerify = (props: IProps) => {
  const intl = i18nUtil.t();
  const { visible, onCancel, supportValidChannel, currentMode, confirm } = props;

  const _confirm = (typeList: IVerificationType[]) => {
    confirm && confirm(typeList);
    onCancel && onCancel();
  };
  return (
    <ModalBase
      footer={null}
      buttonType={"only"}
      open={visible}
      onCancel={onCancel}
      title={intl["choose.verification.method"]}>
      <SwitchVerifyMultiList
        supportValidChannel={supportValidChannel}
        confirm={_confirm}
        onCancel={onCancel}
        currentMode={currentMode}
      />
    </ModalBase>
  );
};
