import dynamic from "next/dynamic";

export const DownloadHeader = dynamic(() => import("./Header"), {
  ssr: false
});
export const Affix = dynamic(() => import("./Affix"), {
  ssr: false
});
export const About = dynamic(() => import("./About"), {
  ssr: false
});
export const AboutModule = dynamic(() => import("./AboutModule"), {
  ssr: false
});
