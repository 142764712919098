import {
  autoInvestList,
  fetchYieldProducts,
  // fixedProductsList,
  getAssetsSituational,
  getStrategyList,
  getVentureDetail,
  getVentureList,
  getWalletInfo,
  getWalletSpotAccounts,
  stakingProductsList,
  getAssetCurrencyConfig,
  getStrategyDetail,
  newFixedProductsList
} from "@aspen/services/index";
import {
  updateAssetsLineData,
  updateDepositCoinList,
  updateSpotAccountList,
  updateWalletInfo,
  updateYieldStakingList,
  useAppDispatch
} from "@aspen/store";
import { DATA_CACHES_KEYS } from "@aspen/services";
import { COMMON_CACHE_PRIORTY_KEYS, ICommonCacheKeys } from "@aspen/model";
import { isClient } from "@aspen/libs";
import localforage from "localforage";

/**
 * @param key 唯一key名称
 * @param params 请求参数
 */

function useFecthers(key: ICommonCacheKeys, params: any, cacheKey?: string) {
  if (!key) return;
  const dispatch = useAppDispatch();

  const updateBeforeRequest = (updateFun: Function) => {
    if (isClient && cacheKey) {
      localforage.getItem(cacheKey).then((cacheData: any) => {
        const _cacheResDataKey = DATA_CACHES_KEYS[key].cacheResDataKey;
        try {
          if (_cacheResDataKey) {
            cacheData?.[_cacheResDataKey] && dispatch(updateFun(cacheData?.[_cacheResDataKey]));
          } else {
            dispatch(updateFun(cacheData));
          }
        } catch (error) {
          // ignore
        }
      });
    }
  };

  const fetcherWalletInfo = () => {
    updateBeforeRequest(updateWalletInfo);
    const param = DATA_CACHES_KEYS[key].param;
    return getWalletInfo(param).then((res) => {
      if (res?.code == "0") {
        dispatch(updateWalletInfo(res?.data));
        return res?.data;
      }
    });
  };

  const fetcherStrategiesList = () => {
    const param = DATA_CACHES_KEYS[key].param;
    return getStrategyList(param).then((res) => {
      if (res?.code == "0") {
        return res?.data?.rows;
      }
    });
  };

  const fetcherVentureList = () => {
    const params = DATA_CACHES_KEYS[key].param;
    return getVentureList(params).then((res) => {
      if (res?.code == "0") {
        return res?.data?.rows;
      }
    });
  };

  const fetcherStrategieDetail = () => {
    return getStrategyDetail({ strategyId: params.id }).then((res) => {
      if (res?.code == "0") {
        return res?.data;
      }
    });
  };

  const fetcherVentureDetail = () => {
    return getVentureDetail({ ventureInfoId: params.id }).then((res) => {
      if (res?.code == "0") {
        return res?.data;
      }
    });
  };
  const fetcherDepositCoinList = () => {
    updateBeforeRequest(updateDepositCoinList);
    return getAssetCurrencyConfig().then((res) => {
      if (res?.code == "0") {
        dispatch(updateDepositCoinList(res?.data));
        return res?.data;
      }
    });
  };
  const fetcherSpotAccounts = () => {
    updateBeforeRequest(updateSpotAccountList);
    return getWalletSpotAccounts().then((res) => {
      if (res?.code == "0") {
        dispatch(updateSpotAccountList(res?.data));
        return res?.data;
      }
    });
  };

  const fecherAssetsSituational = () => {
    updateBeforeRequest(updateAssetsLineData);
    return getAssetsSituational(DATA_CACHES_KEYS[key].param).then((res) => {
      if (res?.code == "0") {
        dispatch(updateAssetsLineData(res?.data));
        return res?.data;
      }
    });
  };

  const fecherYieldFlexibleProducts = () => {
    return fetchYieldProducts(DATA_CACHES_KEYS[key].param).then((res) => {
      if (res?.code == "0") {
        return res?.data?.financingProducts;
      }
    });
  };
  const fecherYieldFixedProductsList = () => {
    return newFixedProductsList(DATA_CACHES_KEYS[key].param).then((res) => {
      if (res?.code == "0") {
        return res?.data;
      }
    });
  };
  const fecherStakingProducts = () => {
    updateBeforeRequest(updateYieldStakingList);
    return stakingProductsList(DATA_CACHES_KEYS[key].param).then((res) => {
      if (res?.code == "0") {
        dispatch(updateYieldStakingList(res?.data?.rows ?? []));
        return res?.data;
      }
    });
  };
  const fecherAutoInvestList = () => {
    return autoInvestList(DATA_CACHES_KEYS[key].param).then((res) => {
      if (res?.code == "0") {
        return res?.data;
      }
    });
  };
  let fetcher;
  switch (key) {
    case COMMON_CACHE_PRIORTY_KEYS.walletInfo:
      fetcher = fetcherWalletInfo;
      break;
    case COMMON_CACHE_PRIORTY_KEYS.strategies:
      fetcher = fetcherStrategiesList;
      break;
    case COMMON_CACHE_PRIORTY_KEYS.primaryVentureList:
      fetcher = fetcherVentureList;
      break;
    case COMMON_CACHE_PRIORTY_KEYS.secondaryVentureList:
      fetcher = fetcherVentureList;
      break;
    case COMMON_CACHE_PRIORTY_KEYS.ventureDetail:
      fetcher = fetcherVentureDetail;
      break;
    case COMMON_CACHE_PRIORTY_KEYS.strategiesDetail:
      fetcher = fetcherStrategieDetail;
      break;
    case COMMON_CACHE_PRIORTY_KEYS.depositCoinList:
      fetcher = fetcherDepositCoinList;
      break;
    case COMMON_CACHE_PRIORTY_KEYS.spotAccounts:
      fetcher = fetcherSpotAccounts;
      break;
    case COMMON_CACHE_PRIORTY_KEYS.assetsLineData:
      fetcher = fecherAssetsSituational;
      break;
    case COMMON_CACHE_PRIORTY_KEYS.yieldFlexibleProducts:
      fetcher = fecherYieldFlexibleProducts;
      break;
    case COMMON_CACHE_PRIORTY_KEYS.yieldFixedProducts:
      fetcher = fecherYieldFixedProductsList;
      break;
    case COMMON_CACHE_PRIORTY_KEYS.stakingProducts:
      fetcher = fecherStakingProducts;
      break;
    case COMMON_CACHE_PRIORTY_KEYS.autoInvestList:
      fetcher = fecherAutoInvestList;
      break;
    default:
  }
  return fetcher;
}

export { useFecthers };
