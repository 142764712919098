import React, { memo } from "react";
import { Progress } from "antd";
import styles from "./AssetsProgress.module.less";

interface IProps {
  progressList: Array<{
    title: string;
    value: string | number;
    percent: number;
    color: string;
  }>;
  showAssets: boolean;
}

export const AssetsProgress: React.FC<IProps> = memo((props) => {
  const { progressList, showAssets } = props;

  return (
    <div className={styles.container}>
      {progressList.map((item) => {
        return (
          <div className={styles.progressArea} key={item.title}>
            <div className={styles.title}>
              <div className={styles.circleIcon} style={{ borderColor: item.color }} />
              <span>{item.title}</span>
            </div>
            <div className={styles.line}>
              <Progress
                type="line"
                showInfo={false}
                strokeColor={item.color}
                trailColor="#262938"
                percent={item.percent}
                strokeWidth={4}
              />
            </div>
            <div className={styles.value}>{showAssets ? item.value : "****"}</div>
          </div>
        );
      })}
    </div>
  );
});
