import dynamic from "next/dynamic";

export const SymbolBox = dynamic(() => import("./listComponent/SymbolBox"), {
  ssr: false
});
export const SymbolDetail = dynamic(() => import("./listComponent/SymbolDetail"), {
  ssr: false
});
export const TableList = dynamic(() => import("./listComponent/TableList"), {
  ssr: false
});
export const Customization = dynamic(() => import("./listComponent/Customization"), {
  ssr: false
});
export const ModalCustomization = dynamic(() => import("./listComponent/ModalCustomization"), {
  ssr: false
});
export const Timeline = dynamic(() => import("./listComponent/Timeline"), {
  ssr: false
});

export const SettlementChart = dynamic(() => import("./accumulatorSubscribe/SettlementChart"), {
  ssr: false
});

export const Settlement = dynamic(() => import("./accumulatorSubscribe/Settlement"), {
  ssr: false
});

export const ModalSuspend = dynamic(() => import("./accumulatorSubscribe/ModalSuspend"), {
  ssr: false
});

export const ModalSubscribeResult = dynamic(
  () => import("./accumulatorSubscribe/ModalSubscribeResult"),
  {
    ssr: false
  }
);

export const KycGuideTips = dynamic(() => import("@aspen/ui/kycGuideTips/KycGuideTips"), {
  ssr: false
});

export const TabLabel = dynamic(() => import("./TabLabel"), {
  ssr: false
});

export const InitialPriceTooltip = dynamic(
  () => import("./accumulatorSubscribe/InitialPriceTooltip"),
  {
    ssr: false
  }
);

export const AccumulatorTab = dynamic(() => import("./accumulator/AccumulatorTab"), {
  ssr: false
});

export const SnowballTab = dynamic(() => import("./snowball/SnowballTab"), {
  ssr: false
});

export const CoinImage = dynamic(() => import("@aspen/ui/coinImage/CoinImage"), {
  ssr: false
});

export const SnowballDetail = dynamic(() => import("./snowballSubscribe/SnowballDetail"), {
  ssr: false
});

export const SnowballCase = dynamic(() => import("./snowballSubscribe/SnowballCase"), {
  ssr: false
});
export * from "./StructuredTableWrapper";
