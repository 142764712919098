import React from "react";
import dynamic from "next/dynamic";
import styles from "@aspen/theme/Home.module.less";
import { Spin } from "antd";

// 首页轮播通知
export const Announcements = dynamic(() => import("./Announcements"), {
  loading: () => (
    <div className={styles.notice + " middleAlign"}>
      <Spin />
    </div>
  ),
  ssr: false
});
// 首页推荐策略
export const WellStrategy = dynamic(() => import("./WellStrategy"), {
  loading: () => (
    <div className={styles.wellStrategy + " middleAlign"} style={{ height: "533px" }}>
      <Spin />
    </div>
  )
});
// 首页推荐Venture
export const WellVenture = dynamic(() => import("./WellVenture"), {
  loading: () => (
    <div className={styles.wellVenture + " middleAlign"} style={{ height: "533px" }}>
      <Spin />
    </div>
  )
});
// 首页推荐理财
export const PopularSavings = dynamic(() => import("./PopularSavings"), {
  loading: () => (
    <div className={styles.popularSavings + " middleAlign"} style={{ height: "521px" }}>
      <Spin />
    </div>
  )
});

export const ModalNeedChangePwd = dynamic(() => import("./modalNeedChangePwd/ModalNeedChangePwd"), {
  ssr: false
});

export const NotKyc2Mask = dynamic(() => import("./notKyc2Mask/NotKyc2Mask"), {
  ssr: false
});

export const ModalNoDeposit = dynamic(() => import("./modalNoDeposit/ModalNoDeposit"), {
  ssr: false
});
// 参照上面的格式，把packages/ui/widgets/home目录的所有组件都引入进来

export const MarketsCard = dynamic(() => import("./MarketsCard"), {
  ssr: false
});
export const VipInfoCard = dynamic(() => import("./VipInfoCard"), {
  ssr: false
});
export const YieldComponent = dynamic(() => import("./YieldComponent"), {
  ssr: false
});
export const EventEntranceView = dynamic(() => import("./EventEntranceView"), {
  ssr: false
});
