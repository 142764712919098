import React from "react";

import { Modal } from "antd";
import { WithTrimInput } from "@aspen/ui/withTrimInput";
import styles from "./ModalGoogleAuth.module.less";
import { i18nUtil, VerifyCode_MAX_LENGTH } from "@aspen/libs";
import { ModalLoadingContext } from "@aspen/store";

const defaultProps = {};

type IProps = {
  visible: boolean;
  handleCancel: () => void;
  onOk: (googleCode?: string | number) => void;
  businessType?: number;
  // 暂时没有场景需要前置校验谷歌身份验证码
  verifyGoogleCode?: (
    googleCode: number | string,
    businessType: number
  ) => { consecutiveErrors?: boolean; verifyStatus?: string | number };
} & Partial<typeof defaultProps>;

interface IState {
  disabled: boolean;
  googleCode: number | string;
}

class ModalGoogleAuth extends React.PureComponent<IProps, IState> {
  static contextType = ModalLoadingContext;

  constructor(props) {
    super(props);
    this.state = {
      googleCode: "",
      disabled: true
    };
    this.setGoogleCode = this.setGoogleCode.bind(this);
  }

  handleOk: () => void = async () => {
    this.props.onOk(this.state.googleCode);
  };

  clickOnhandleCancel: () => void = () => {
    this.props.handleCancel();
  };

  setGoogleCode(e: React.ChangeEvent<HTMLInputElement>): void {
    const val = e.target.value;
    const onlyNumber = val.replace(/[^0-9]/gi, "");
    this.setState({
      googleCode: onlyNumber
    });
  }

  render(): React.ReactNode {
    const intl = i18nUtil.t();
    // @ts-ignore
    const { confirmLoading } = this.context;
    return (
      <Modal
        destroyOnClose
        wrapClassName={styles.modal}
        title={intl["profile.basic.info.google.auth"]}
        open={this.props.visible}
        afterClose={() => {
          this.setState({ googleCode: "" });
        }}
        confirmLoading={confirmLoading}
        onOk={() => this.handleOk()}
        centered
        okText={intl["button.confirm"]}
        cancelText={intl["button.cancel"]}
        width={480}
        onCancel={this.clickOnhandleCancel}>
        <p className={styles.desc}>{intl["google.auth.tips"]}</p>

        <WithTrimInput
          placeholder={intl["google.auth.tips.placeholder"]}
          maxLength={VerifyCode_MAX_LENGTH}
          value={this.state.googleCode}
          onChange={(event) => {
            this.setGoogleCode(event);
          }}
        />
      </Modal>
    );
  }
}

export { ModalGoogleAuth };
