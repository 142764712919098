import React from "react";
import { Spin, Empty } from "antd";
import styles from "@aspen/theme/Venture.module.less";
import { i18nUtil, VENTURES_TAB_ACTION } from "@aspen/libs";
import { COMMON_CACHE_PRIORTY_KEYS } from "@aspen/model";
import { useCachePriorityData } from "@aspen/hooks";
import VentureItem from "./VentureItem/VentureItem";

interface IProps {}

const SecondaryView: React.FC<IProps> = () => {
  const intl = i18nUtil.t();
  // @ts-ignore
  const { data: ventureListData, isLoading } = useCachePriorityData<any>(
    COMMON_CACHE_PRIORTY_KEYS.secondaryVentureList
  );

  return (
    <Spin spinning={isLoading}>
      <div className={styles.venture} style={{ marginTop: "34px" }}>
        {ventureListData && ventureListData.length > 0 ? (
          <div className={styles.ventureList}>
            {ventureListData.map((item, index) => (
              <div className={styles.ventureListItem} key={index}>
                <VentureItem key={item.id} data={item} tabName={VENTURES_TAB_ACTION.secondary} />
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.ventureEmpty}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={intl["empty.list"]} />
          </div>
        )}
      </div>
    </Spin>
  );
};

export const Secondary = React.memo(SecondaryView);
