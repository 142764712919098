import dynamic from "next/dynamic";

export const StrategyItem = dynamic(() => import("./strategy/StrategyItem"), {
  ssr: false
});

export const Disclaimer = dynamic(() => import("@aspen/ui/disclaimer/Disclaimer"), {
  ssr: false
});

export const ModalSubscribeRedeem = dynamic(() => import("./ModalSubscribeRedeem"), {
  ssr: false
});

export const ModalStrategyResult = dynamic(() => import("./ModalResult"), {
  ssr: false
});
