export const sessionStorageUtils = {
  setItem: (name: string, value: string) => {
    try {
      sessionStorage.setItem(name, value);
    } catch (error) {
      console.log("Failed to execute 'setItem' on 'Storage'");
    }
  },
  getItem: (name: string) => {
    try {
      return sessionStorage.getItem(name);
    } catch (error) {
      console.log("Failed to execute 'getItem' on 'Storage'");
    }
  }
};
