"use client";
import React, { useRef, useState } from "react";
import AlicloudNCCaptcha from "../login/alicloudNCCaptcha";
interface IProps {
  [key: string]: any; // 保留此any, HOC组件，props可能为任意类型
}

/* 登录滑动验证 */
export const WithAliCloudNC = (WrappedComponent) => {
  const withAliCloudNC: React.FC<IProps> = (props: IProps) => {
    // 滑动验证参数
    // @ts-ignore @赵靖 check TODO
    const [ncVal, setNcVal] = useState<Record<string, any>>(null);
    const NCRef = useRef(null);
    const initNC = () => {
      // 无痕验证失败，启动滑动验证
      if (!ncVal) {
        // @ts-ignore @赵靖 check TODO
        NCRef.current.setVisible(true);
        // @ts-ignore @赵靖 check TODO
        NCRef.current.init("nc_login_h5", (data) => {
          setNcVal(data);
          // 滑动验证通过后关闭弹框
          // @ts-ignore @赵靖 check TODO
          NCRef.current.setVisible(false);
        });
      }
    };
    const resetNC = () => {
      // 二次滑动验证失败
      // @ts-ignore @赵靖 check TODO
      NCRef.current.reset();
    };
    return (
      <>
        <WrappedComponent
          {...props}
          ncVal={ncVal}
          setNcVal={setNcVal}
          initNC={initNC}
          resetNC={resetNC}
        />
        <AlicloudNCCaptcha ref={NCRef} />
      </>
    );
  };
  return withAliCloudNC;
};
