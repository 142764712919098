import React from "react";

import styles from "./loading.module.less";

interface IProps {
  loading: boolean;
}

interface IState {}

class Loading extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { loading } = this.props;

    if (!loading) {
      return null;
    }

    return (
      <div className={styles.loadingContainer}>
        <div className={styles.circleLoadingSvg}>
          <svg height="50" width="50" viewBox="0 0 50 50">
            <circle className={styles.circle} cx="25" cy="25" r="20" />
          </svg>
        </div>
      </div>
    );
  }

  componentDidMount() {}

  componentWillUnmount() {}
}

export { Loading };
