import React from "react";
import { Dropdown } from "antd";
import Image from "next/image";
import { i18nUtil, SHOW_LANGUAGE } from "@aspen/libs";
import img_language from "@aspen/assets/images/language.png";
import { DownOutlined } from "@ant-design/icons";
import styles from "./Login.module.less";
import SwitchLanguage from "@aspen/ui/switchLanguage/SwitchLanguage";

const LanguageSwitch: React.FC = () => {
  const intl = i18nUtil.t();

  const getPopupContainer = (trigger) => trigger.parentNode || document.body;

  return (
    <div
      style={{
        position: "fixed",
        top: "50px",
        right: "50px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        zIndex: 9
      }}>
      {SHOW_LANGUAGE && (
        <Dropdown
          overlay={<SwitchLanguage />}
          overlayClassName={styles.languageDropdown}
          trigger={["click"]}
          placement="bottom"
          getPopupContainer={getPopupContainer}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Image unoptimized src={img_language} alt="" width="16" height="16" />
            <span style={{ margin: "0 8px" }}>{intl["currentLocale"]}</span>
            <DownOutlined />
          </div>
        </Dropdown>
      )}
    </div>
  );
};

export default React.memo(LanguageSwitch);
